/* eslint-disable @typescript-eslint/naming-convention */


/**
 *  Participant stats scored goals
 *  @author Livescore <@score-stats.com>
 *  @copyright 2022 livescore
 */

import { SportConfigToken } from '@config/sport.config';
import { Component, Inject, OnInit } from '@angular/core';
import { ParticipantStats } from '@components/participant/statistics/participant-stats.abstract';
import { delay, tap } from 'rxjs/operators';

import { LastForm } from '@widgets/interfaces/widget.interface';
import { forEach } from 'lodash-es';

@Component({
    selector: 'app-participant-stats-scored-goals',
    templateUrl: './participant-stats-scored-goals.component.html',
})
export class ParticipantStatsScoredGoalsComponent extends ParticipantStats implements OnInit {
    public times: [number, number][] = [[0, 15], [16, 30], [31, 45], [45, 60], [61, 75], [76, 90]];

    public total: Record<string, number> = {};
    public home: Record<string, number> = {};
    public away: Record<string, number> = {};

    public totalConcated: Record<string, number> = {};
    public homeConcated: Record<string, number> = {};
    public awayConcated: Record<string, number> = {};

    public hasTimelineData = false;

    public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>) {
        super();
    }

    public reset(): void {
        this.total = {};
        this.home = {};
        this.away = {};

        this.totalConcated = {};
        this.homeConcated = {};
        this.awayConcated = {};
    }

    public ngOnInit(): void {
        this.dataLoaded.pipe(tap(() => {
            if (this.sportConfig.isHockey(this.sportName)) {
                this.periods = ['score_period_1', 'score_period_2', 'score_period_3', 'score_normal_time'];
            }
        }), delay(100)).subscribe(() => {
            this.form.form.forEach(((f: LastForm) => {
                const isHome = this.isHome(f.home_team);


                if (!f.timeline || f.timeline.length === 0) { return; }


                f.timeline.forEach((val) => {
                    if (val.type === this.sportConfig.timeline.eventTypes.scoreChange) {
                        this.hasTimelineData = true;
                        const findPeriod = this.times.find(
                            (t: [number, number]) => t[0] <= +val.match_time && t[1] >= +val.match_time);

                        if (!findPeriod || !val.match_time) {
                            return;
                        }
                        const [start, end] = findPeriod;
                        const periodString = `${start}-${end}`;


                        if ((val.competitor === 'home' && isHome) || (val.competitor === 'away' && !isHome)) {
                            if (periodString in this.total === false) {
                                this.total = { ...this.total, [periodString]: 0 };
                                this.home = { ...this.home, [periodString]: 0 };
                                this.away = { ...this.away, [periodString]: 0 };
                            }

                            this.total[periodString] += 1;

                            if (isHome) {
                                this.home[periodString] += 1;
                            } else {
                                this.away[periodString] += 1;
                            }
                        } else {
                            if (periodString in this.totalConcated === false) {
                                this.totalConcated = { ...this.totalConcated, [periodString]: 0 };
                                this.homeConcated = { ...this.homeConcated, [periodString]: 0 };
                                this.awayConcated = { ...this.awayConcated, [periodString]: 0 };
                            }

                            this.totalConcated[periodString] += 1;
                            if (isHome) {
                                this.homeConcated[periodString] += 1;
                            } else {
                                this.awayConcated[periodString] += 1;
                            }
                        }
                    }
                });
            }));
        });
    }

    public getWidth(type: 'home' | 'away' | 'homeConcated' | 'awayConcated', period: string): number {
        if (!this[type][period]) {
            return 0;
        }

        let sum = 0;
        forEach(this[type], (key) => { sum += key; });

        return Math.round(this[type][period]! / sum * 100);
    }

    public lighter(type: 'total' | 'totalConcated', period: string): Record<string, boolean> {
        if (!this[type][period]) {
            return { '--lighter-0': true };
        }

        const periodNum = this[type][period];
        const vals = new Set();
        forEach(this[type], key => vals.add(key));

        const numAr = Array.from(vals).sort().reverse();

        const index = numAr.findIndex(v => v === periodNum);

        if (index === -1) {
            return { '--lighter-0': true };
        }

        return { [`--lighter-${index + 1}`]: true };
    }
}
