import { SearchService } from '@services/search.service';
import { Component, ElementRef, Input, ViewChild, AfterViewInit, ChangeDetectorRef, OnChanges }
    from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { SearchInterface } from '@interfaces/search.interface';
import { BaseComponent } from '@components/base.component';
import { Router } from '@angular/router';
import SPORT_CONFIG from '@config/sport.config';

@Component({
    selector: 'app-participant-comparition',
    templateUrl: './participant-comparition.component.html',
    styleUrls: ['./participant-compartion.component.scss'],
})
export class ParticipantComparitionComponent extends BaseComponent implements OnChanges, AfterViewInit {
    @ViewChild('first', { static: false })
    firstElement: ElementRef<HTMLInputElement>;

    @ViewChild('second', { static: false })
    secondElement: ElementRef<HTMLInputElement>;

    @Input()
    sportId: number;

    @Input()
    sportName: string;

    public loading: boolean = false;

    public $first: Observable<SearchInterface[]>;
    public $second: Observable<SearchInterface[]>;

    public participantFirst: SearchInterface | null = null;
    public participantSecond: SearchInterface | null = null;

    public findFirst: boolean = false;
    public findSecond: boolean = false;

    public constructor(public search: SearchService,
        private router: Router, private changeDetector : ChangeDetectorRef) {
        super();
    }


    public ngOnChanges(): void {
        this.resetVal('first');
        this.resetVal('second');
    }

    public ngAfterViewInit(): void {
        this.init();
    }

    public doCompare(): void {
        this.router.navigate(['/compare', `${this.sportName}-${this.sportId}`,
            this.participantFirst?.id, this.participantSecond?.id]);
    }

    public selectParticipant(type: 'first' | 'second', data: SearchInterface): void {
        if (type === 'first') {
            this.participantFirst = data;
        } else {
            this.participantSecond = data;
        }

        this.firstElement.nativeElement.value = '';
        this.firstElement.nativeElement.dispatchEvent(new KeyboardEvent('keyup'));
        this.secondElement.nativeElement.value = '';
        this.secondElement.nativeElement.dispatchEvent(new KeyboardEvent('keyup'));
    }

    get who(): string {
        return SPORT_CONFIG.displayType.participantPerson.includes(this.sportName) ? 'person' : 'team';
    }


    public resetVal(type: 'first' | 'second' | null): void {
        if (type === 'first') {
            this.participantFirst = null;
        }

        if (type === 'second') {
            this.participantSecond = null;
        }


        this.changeDetector.detectChanges();
        this.init(type);
    }

    get isValid(): boolean {
        return this.participantFirst !== null && this.participantSecond !== null;
    }

    private init(type: 'first' | 'second' | null = null): void {
        if (type === null || type === 'first') {
            this.$first = fromEvent(this.firstElement.nativeElement, 'keyup').pipe(
                tap(() => {
                    this.loading = true;
                }),
                map((i: any) => i.currentTarget.value),
                debounceTime(500),
                switchMap((val: string) => this.search.get(val, this.sportId)),
                map((v: Record<string, any>) => v.participants),
                tap(() => {
                    this.loading = false;
                }),
            );
        }
        if (type === null || type === 'second') {
            this.$second = fromEvent(this.secondElement.nativeElement, 'keyup').pipe(
                tap(() => {
                    this.loading = true;
                }),
                map((i: any) => i.currentTarget.value),
                debounceTime(500),
                switchMap((val: string) => this.search.get(val, this.sportId)),
                map((v: Record<string, any>) => v.participants),
                tap(() => {
                    this.loading = false;
                }),
            );
        }
    }
}
