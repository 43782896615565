<ul class="nav nav-pills bg-light px-2 app-league-table-container app-league-table-container--big">
    <li *ngFor="let playerT of playerTournament; trackBy: trackByIndex" class="nav-item">
        <a
            (click)="activePlayerT(playerT.id)"
            [class.active]="playerT.id === playerSelectedT"
            class="nav-link active app-cursor"
            >{{ playerT.name }}</a
        >
    </li>
    <li *ngIf="playerTournament.length > 0">
        <a (click)="activePlayerT(0)" [class.active]="0 === playerSelectedT" class="nav-link active app-cursor">{{
            'web.total' | translate
        }}</a>
    </li>
</ul>
<div class="d-flex table-scrollable">
    <div class="table-scrollable__data flex-grow-1" tableScroll>
        <table class="table-scrollable__data__table" data-cy="players-squad" id="players-squad">
            <thead>
                <tr class="players-squad-virtual" id="players-squad-virtual" style="position: relative; top: 0px">
                    <th class="table-scrollable__table-th table-scrollable__data__table-th">&nbsp;</th>
                    <th class="table-scrollable__table-th table-scrollable__data__table-th">&nbsp;</th>
                    <th class="table-scrollable__table-th table-scrollable__data__table-th">
                        {{ 'web.player_position' | translate }}
                    </th>
                    <th class="table-scrollable__table-th table-scrollable__data__table-th">
                        {{ 'web.player_age' | translate }}
                    </th>
                    <th
                        *ngIf="showMatchPlayed"
                        class="table-scrollable__table-th table-scrollable__data__table-th"
                        title="{{ 'web.matches_played' | translate }}"
                    >
                        <i class="fas fa-stopwatch"></i>
                    </th>
                    <th
                        *ngIf="showStats"
                        class="table-scrollable__table-th table-scrollable__data__table-th"
                        title="{{ 'web.goal' | translate }}"
                    >
                        <i *ngIf="isHockey(sportName); else other" class="fas fa-hockey-puck v-mid"></i>
                        <ng-template #other><i class="far fa-futbol v-mid"></i></ng-template>
                    </th>
                    <th
                        *ngIf="hasAssists"
                        class="table-scrollable__table-th table-scrollable__data__table-th"
                        title="{{ 'web.assists_for_team' | translate }}"
                    >
                        <strong>A</strong>
                    </th>
                    <ng-container *ngIf="hasCards">
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th"
                            title="{{ 'web.yellow_card' | translate }}"
                        >
                            <svg class="icon icon--12 icon--yellow" id="yellow-card">
                                <use href="#icon-card-yellow" />
                            </svg>
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th"
                            title="{{ 'web.red_card' | translate }}"
                        >
                            <svg class="icon icon--12 icon--danger" id="red-card">
                                <use href="#icon-card-yellow" />
                            </svg>
                        </th>
                    </ng-container>
                    <th
                        class="table-scrollable__table-th table-scrollable__data__table-th"
                        title="{{ 'web.player_missing' | translate }}"
                    >
                        &nbsp;
                    </th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let val of finalData | keys; trackBy: trackByFn">
                    <tr class="match-info__table__row player-record bg-dark text-white">
                        <td [attr.colspan]="colspan" class="font-weight-bold text-uppercase py-1 pl-4">
                            <ng-container *ngIf="val.key !== null && val.key != 'null'; else noposition">
                                {{ 'web.position_' + sportName + '_' + val.key | lowercase | translate }}
                            </ng-container>
                            <ng-template #noposition> &nbsp; </ng-template>
                        </td>
                    </tr>
                    <tr
                        *ngFor="let player of val.value; trackBy: trackByFn"
                        class="table-scrollable__table-row"
                        data-cy="players-squad-row"
                    >
                        <td class="table-scrollable__table-td table-scrollable__data__table-td">
                            <div class="table-scrollable__table-place">
                                <span class="span">
                                    <ng-container *ngIf="player.number != 0">{{ player.number }}</ng-container>
                                </span>
                            </div>
                        </td>

                        <td class="table-scrollable__table-td table-scrollable__data__table-td">
                            <div class="d-flex">
                                <div class="img-circle img-circle--60 mr-3 rounded-circle">
                                    <img
                                        (error)="imageError($event, 'avatar')"
                                        [attr.alt]="player.name"
                                        [src]="'/assets/players/photos/' + player.id + '.png'"
                                        height="60"
                                        loading="lazy"
                                    />
                                </div>
                                <img
                                    *ngIf="player.flag !== null"
                                    [attr.alt]=""
                                    [attr.src]="player.flag"
                                    class="mr-1 align-self-center"
                                    height="16"
                                />
                                <a
                                    [queryParams]="{ season: seasonId }"
                                    [routerLink]="['/page/sport/player', sportName + '-' + sportId, player.id]"
                                    class="text-reset table-scrollable-team align-self-center"
                                    title=" {{ player.missing_reason | translate }}"
                                    >{{ player.name }}
                                    <ng-container *ngIf="player.injury || player.missing_global">
                                        &nbsp;

                                        <i class="far fa-times-circle liv-scratch"></i>
                                    </ng-container>
                                </a>
                            </div>
                        </td>
                        <td class="table-scrollable__table-td table-scrollable__data__table-td">
                            <ng-container *ngIf="player.position !== null; else noposition">{{
                                'web.position_' + sportName + '_' + player.position | lowercase | translate
                            }}</ng-container>
                            <ng-template #noposition>
                                <ng-container *ngIf="player.role == 'manager'; else elsenoposition">{{
                                    'web.coach' | translate
                                }}</ng-container>
                                <ng-template #elsenoposition></ng-template>
                            </ng-template>
                        </td>
                        <td class="table-scrollable__table-td table-scrollable__data__table-td">
                            {{ player.age ? player.age : '-' }}
                        </td>

                        <td *ngIf="showMatchPlayed" class="table-scrollable__table-td table-scrollable__data__table-td">
                            {{ playerData(player.statsTournament, 'matches_played') }}
                        </td>
                        <td *ngIf="showStats" class="table-scrollable__table-td table-scrollable__data__table-td">
                            {{ playerData(player.statsTournament, 'goals_scored') }}
                        </td>
                        <td *ngIf="hasAssists" class="table-scrollable__table-td table-scrollable__data__table-td">
                            {{ playerData(player.statsTournament, 'assists') }}
                        </td>
                        <ng-container *ngIf="hasCards">
                            <td class="table-scrollable__table-td table-scrollable__data__table-td">
                                {{ playerData(player.statsTournament, 'yellow_cards') }}
                            </td>
                            <td class="table-scrollable__table-td table-scrollable__data__table-td">
                                {{ playerData(player.statsTournament, 'red_cards') }}
                            </td>
                        </ng-container>

                        <td class="table-scrollable__table-td table-scrollable__data__table-td">
                            <div *ngIf="player.missing_reason" class="text-left app-word-wrap-important app-no-wrap">
                                {{ player.missing_reason | translate }}
                                <!--                                {{ 'web.missing-from' | translate }}-->
                                <!--                                {{ player.missing_registered | livDate: 'dateShort' }} ({{-->
                                <!--                                    player.missing_reason | translate-->
                                <!--                                }})<span *ngIf="player.missing_expected_back"-->
                                <!--                                    >. {{ 'web.expected-back' | translate }}-->
                                <!--                                    {{ player.missing_expected_back | livDate: 'dateShort' }}</span-->
                                <!--                                >-->
                                <!--                                <span *ngIf="!player.missing_global"-->
                                <!--                                    >, {{ 'web.missing-in-tournament' | translate }}-->
                                <!--                                    {{ player.missing_subtournament }}</span-->
                                <!--                                >-->
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <p class="pl-2"><i class="far fa-times-circle liv-scratch"></i> {{ 'web.missing_player' | translate }}</p>
    </div>
</div>
