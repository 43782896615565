

/**
 *  Participant stats goals
 *  @author Livescore <@score-stats.com>
 *  @copyright 2022 livescore
 */

import { SportConfigToken } from '@config/sport.config';
import { Component, Inject, OnInit } from '@angular/core';
import { ParticipantStats } from '@components/participant/statistics/participant-stats.abstract';
import { delay, tap } from 'rxjs/operators';

import { LastForm } from '@widgets/interfaces/widget.interface';


@Component({
    selector: 'app-participant-stats-goals',
    templateUrl: './participant-stats-goals.component.html',
})
export class ParticipantStatsGoalsComponent extends ParticipantStats implements OnInit {
    [prop: string]: any;

    private total: number = 0;
    private totalHome: number = 0;
    private totalAway: number = 0;

    private averageFirstMinuteGoal: number = 0;
    private averageFirstMinuteGoalHome: number = 0;
    private averageFirstMinuteGoalAway: number = 0;

    private total2: number = 0;
    private totalHome2: number = 0;
    private totalAway2: number = 0;

    private averageMinuteGoal: number = 0;
    private averageMinuteGoalHome: number = 0;
    private averageMinuteGoalAway: number = 0;

    private total3: number = 0;
    private totalHome3: number = 0;
    private totalAway3: number = 0;

    private averageFirstMinuteGoalConcated: number = 0;
    private averageFirstMinuteGoalHomeConcated: number = 0;
    private averageFirstMinuteGoalAwayConcated: number = 0;

    private total4: number = 0;
    private totalHome4: number = 0;
    private totalAway4: number = 0;

    private averageMinuteGoalConcated: number = 0;
    private averageMinuteGoalHomeConcated: number = 0;
    private averageMinuteGoalAwayConcated: number = 0;

    public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>) {
        super();
    }

    public reset(): void {
        this.total = 0;
        this.totalHome = 0;
        this.totalAway = 0;

        this.averageFirstMinuteGoal = 0;
        this.averageFirstMinuteGoalHome = 0;
        this.averageFirstMinuteGoalAway = 0;

        this.total2 = 0;
        this.totalHome2 = 0;
        this.totalAway2 = 0;

        this.averageMinuteGoal = 0;
        this.averageMinuteGoalHome = 0;
        this.averageMinuteGoalAway = 0;

        this.total3 = 0;
        this.totalHome3 = 0;
        this.totalAway3 = 0;

        this.averageFirstMinuteGoalConcated = 0;
        this.averageFirstMinuteGoalHomeConcated = 0;
        this.averageFirstMinuteGoalAwayConcated = 0;

        this.total4 = 0;
        this.totalHome4 = 0;
        this.totalAway4 = 0;

        this.averageMinuteGoalConcated = 0;
        this.averageMinuteGoalHomeConcated = 0;
        this.averageMinuteGoalAwayConcated = 0;
    }


    public ngOnInit(): void {
        this.dataLoaded.pipe(tap(() => {
            if (this.sportConfig.isHockey(this.sportName)) {
                this.periods = ['score_period_1', 'score_period_2', 'score_period_3', 'score_normal_time'];
            }
        }), delay(100)).subscribe(() => {
            this.form.form.forEach(((f: LastForm) => {
                const isHome = this.isHome(f.home_team);
                let firstGoal = false;
                let firstGoalConcated = false;


                if (!f.timeline || f.timeline.length === 0) { return; }


                f.timeline.forEach((val) => {
                    if (val.type === 'score_change') {
                        if (!firstGoal) {
                            if (isHome && val.competitor === 'home' && +val.home_score === 1) {
                                this.averageFirstMinuteGoal += +val.match_time;
                                this.averageFirstMinuteGoalHome += +val.match_time;
                                firstGoal = true;
                                this.totalHome += 1;
                                this.total += 1;
                            } else if (!isHome && val.competitor === 'away' && +val.away_score === 1) {
                                this.averageFirstMinuteGoal += +val.match_time;
                                this.averageFirstMinuteGoalAway += +val.match_time;
                                firstGoal = true;
                                this.totalAway += 1;
                                this.total += 1;
                            }
                        }

                        if (!firstGoalConcated) {
                            if (isHome && val.competitor === 'away' && +val.away_score === 1) {
                                this.averageFirstMinuteGoalConcated += +val.match_time;
                                this.averageFirstMinuteGoalHomeConcated += +val.match_time;
                                firstGoalConcated = true;
                                this.totalHome3 += 1;
                                this.total3 += 1;
                            } else if (!isHome && val.competitor === 'home' && +val.home_score === 1) {
                                this.averageFirstMinuteGoalConcated += +val.match_time;
                                this.averageFirstMinuteGoalAwayConcated += +val.match_time;
                                firstGoalConcated = true;
                                this.totalAway3 += 1;
                                this.total3 += 1;
                            }
                        }


                        if (isHome && val.competitor === 'home') {
                            this.averageMinuteGoal += +val.match_time;
                            this.averageMinuteGoalHome += +val.match_time;

                            this.totalHome2 += 1;
                            this.total2 += 1;
                        } else if (!isHome && val.competitor === 'away') {
                            this.averageMinuteGoal += +val.match_time;
                            this.averageMinuteGoalAway += +val.match_time;

                            this.totalAway2 += 1;
                            this.total2 += 1;
                        }

                        if (isHome && val.competitor === 'away') {
                            this.averageMinuteGoalConcated += +val.match_time;
                            this.averageMinuteGoalHomeConcated += +val.match_time;

                            this.totalHome4 += 1;
                            this.total4 += 1;
                        } else if (!isHome && val.competitor === 'home') {
                            this.averageMinuteGoalConcated += +val.match_time;
                            this.averageMinuteGoalAwayConcated += +val.match_time;

                            this.totalAway4 += 1;
                            this.total4 += 1;
                        }
                    }
                });
            }));
        });
    }

    /**
     * Average time per goal concated
     */
    get avgMinGoalTotalConcated(): string | null {
        if (this.averageMinuteGoalConcated === 0) {
            return null;
        }

        return (this.averageMinuteGoalConcated / this.total4).toFixed(1);
    }

    /**
     * Average time per goal  concated Home
     */
    get avgMinGoalTotalHomeConcated(): string | null {
        if (this.averageMinuteGoalHomeConcated === 0) {
            return null;
        }

        return (this.averageMinuteGoalHomeConcated / this.totalHome4).toFixed(1);
    }

    /**
     * Average time per goal  concated Away
     */
    get avgMinGoalTotalAwayConcated(): string | null {
        if (this.averageMinuteGoalAwayConcated === 0) {
            return null;
        }

        return (this.averageMinuteGoalAwayConcated / this.totalAway4).toFixed(1);
    }


    /**
     * Average time per goal
     */
    get avgMinGoalTotal(): string | null {
        if (this.averageMinuteGoal === 0) {
            return null;
        }

        return (this.averageMinuteGoal / this.total2).toFixed(1);
    }

    /**
     * Average time per goal Home
     */
    get avgMinGoalTotalHome(): string | null {
        if (this.averageMinuteGoalHome === 0) {
            return null;
        }

        return (this.averageMinuteGoalHome / this.totalHome2).toFixed(1);
    }

    /**
     * Average time per goal Away
     */
    get avgMinGoalTotalAway(): string | null {
        if (this.averageMinuteGoalAway === 0) {
            return null;
        }

        return (this.averageMinuteGoalAway / this.totalAway2).toFixed(1);
    }

    /**
     * Average minute of the first goal concated
     */
    get avgMinFirstGoalTotalConcated(): string | null {
        if (this.averageFirstMinuteGoalConcated === 0) {
            return null;
        }

        return (this.averageFirstMinuteGoalConcated / this.total3).toFixed(1);
    }

    /**
     * Average minute of the first goal concated Home
     */
    get avgMinFirstGoalTotalHomeConcated(): string | null {
        if (this.averageFirstMinuteGoalHomeConcated === 0) {
            return null;
        }

        return (this.averageFirstMinuteGoalHomeConcated / this.totalHome3).toFixed(1);
    }

    /**
     * Average minute of the first goal concated Away
     */
    get avgMinFirstGoalTotalAwayConcated(): string | null {
        if (this.averageFirstMinuteGoalAwayConcated === 0) {
            return null;
        }

        return (this.averageFirstMinuteGoalAwayConcated / this.totalAway3).toFixed(1);
    }


    /**
     * Average minute of the first goal
     */
    get avgMinFirstGoalTotal(): string | null {
        if (this.averageFirstMinuteGoal === 0) {
            return null;
        }

        return (this.averageFirstMinuteGoal / this.total).toFixed(1);
    }

    /**
     * Average minute of the first goal Home
     */
    get avgMinFirstGoalTotalHome(): string | null {
        if (this.averageFirstMinuteGoalHome === 0) {
            return null;
        }

        return (this.averageFirstMinuteGoalHome / this.totalHome).toFixed(1);
    }

    /**
     * Average minute of the first goal Away
     */
    get avgMinFirstGoalTotalAway(): string | null {
        if (this.averageFirstMinuteGoalAway === 0) {
            return null;
        }

        return (this.averageFirstMinuteGoalAway / this.totalAway).toFixed(1);
    }
}
