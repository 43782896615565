<div>
    <!-- Single/Double/Mix info not in Tennis v2 api -->
    <!-- <ul class="nav nav-pills bg-light px-2 app-league-table-container">
        <li class="nav-item">
            <a
                id="tab-match-h2h-1"
                data-toggle="tab"
                role="tab"
                aria-controls="tab-match-h2h-1-content"
                aria-selected="true"
                class="nav-link active"
                href="#"
                >Single</a
            >
        </li>
        <li class="nav-item">
            <a
                id="tab-match-h2h-6"
                data-toggle="tab"
                role="tab"
                aria-controls="tab-match-h2h-1-content"
                aria-selected="true"
                class="nav-link"
                href="#"
                >Double</a
            >
        </li>
        <li class="nav-item">
            <a
                id="tab-match-h2h-7"
                data-toggle="tab"
                role="tab"
                aria-controls="tab-match-h2h-1-content"
                aria-selected="true"
                class="nav-link"
                href="#"
                >Mixed</a
            >
        </li>
    </ul> -->

    <div class="d-flex table-scrollable">
        <div class="table-scrollable__data flex-grow-1" tableScroll>
            <table data-cy="app-table-right-data" class="table-scrollable__data__table">
                <colgroup>
                    <col [class.--active-row]="activeRow === 1" />
                    <col [class.--active-row]="activeRow === 2" />
                    <col [class.--active-row]="activeRow === 3" />
                    <col [class.--active-row]="activeRow === 4" />
                    <col [class.--active-row]="activeRow === 5" />
                    <col [class.--active-row]="activeRow === 6" />
                    <col [class.--active-row]="activeRow === 7" />
                    <col [class.--active-row]="activeRow === 8" />
                    <col [class.--active-row]="activeRow === 9" />
                    <col [class.--active-row]="activeRow === 10" />
                    <col [class.--active-row]="activeRow === 11" />
                    <col [class.--active-row]="activeRow === 12" />
                    <col [class.--active-row]="activeRow === 13" />
                </colgroup>
                <thead>
                    <tr>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th app-cursor"
                            title=""
                            scope="col"
                        >
                            {{ 'web.season' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th app-cursor font-weight-bold"
                            title=""
                            scope="col"
                        >
                            {{ 'web.tournaments_won' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th app-cursor font-weight-bold"
                            title=""
                            scope="col"
                        >
                            {{ 'web.matches_won' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th app-cursor font-weight-bold"
                            title=""
                            scope="col"
                            colspan="2"
                        >
                            {{ 'web.hardcourt_indoor' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th app-cursor font-weight-bold"
                            title=""
                            scope="col"
                            colspan="2"
                        >
                            {{ 'web.hardcourt_outdoor' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th app-cursor font-weight-bold"
                            title=""
                            scope="col"
                            colspan="2"
                        >
                            {{ 'web.hardcourt_red_clay' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th app-cursor font-weight-bold"
                            title=""
                            scope="col"
                            colspan="2"
                        >
                            {{ 'web.hardcourt_grass' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th app-cursor font-weight-bold"
                            title=""
                            scope="col"
                            colspan="2"
                        >
                            {{ 'web.othercourt' | translate }}
                        </th>
                    </tr>
                    <tr>
                        <th colspan="3">&nbsp;</th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th table-scrollable__table-th--no-border"
                        >
                            {{ 'web.matches' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th table-scrollable__table-th--no-border"
                        >
                            {{ 'web.tournaments' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th table-scrollable__table-th--no-border"
                        >
                            {{ 'web.matches' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th table-scrollable__table-th--no-border"
                        >
                            {{ 'web.tournaments' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th table-scrollable__table-th--no-border"
                        >
                            {{ 'web.matches' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th table-scrollable__table-th--no-border"
                        >
                            {{ 'web.tournaments' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th table-scrollable__table-th--no-border"
                        >
                            {{ 'web.matches' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th table-scrollable__table-th--no-border"
                        >
                            {{ 'web.tournaments' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th table-scrollable__table-th--no-border"
                        >
                            {{ 'web.matches' | translate }}
                        </th>
                        <th
                            class="table-scrollable__table-th table-scrollable__data__table-th table-scrollable__table-th--no-border"
                        >
                            {{ 'web.tournaments' | translate }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-scrollable__table-row" *ngFor="let row of data; trackBy: trackByYear">
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td text-bold"
                            (mouseenter)="activeRow = 1"
                            (mouseleave)="activeRow = 0"
                        >
                            {{ row.year }}
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 2"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container *ngIf="row.competitions_played; else elseTmp">
                                {{ row.competitions_won }}/{{ row.competitions_lost }}
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 3"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container *ngIf="row.matches_played; else elseTmp">
                                {{ row.matches_won }}/{{ row.matches_lost }}
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 4"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container *ngIf="row.hardcourt_indoor_matches_played; else elseTmp">
                                {{ row.hardcourt_indoor_matches_won }}/{{ row.hardcourt_indoor_matches_lost }}
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 5"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container *ngIf="row.hardcourt_indoor_competitions_played; else elseTmp">
                                {{ row.hardcourt_indoor_competitions_won }}/{{ row.hardcourt_indoor_competitions_lost }}
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 6"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container *ngIf="row.hardcourt_outdoor_matches_played; else elseTmp">
                                {{ row.hardcourt_outdoor_matches_won }}/{{ row.hardcourt_outdoor_matches_lost }}
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 7"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container *ngIf="row.hardcourt_outdoor_competitions_played; else elseTmp">
                                {{ row.hardcourt_outdoor_competitions_won }}/{{
                                    row.hardcourt_outdoor_competitions_lost
                                }}
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 8"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container *ngIf="row.red_clay_matches_played; else elseTmp">
                                {{ row.red_clay_matches_won }}/{{ row.red_clay_matches_lost }}
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 9"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container *ngIf="row.red_clay_competitions_played; else elseTmp">
                                {{ row.red_clay_competitions_won }}/{{ row.red_clay_competitions_lost }}
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 10"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container *ngIf="row.grass_matches_played; else elseTmp">
                                {{ row.grass_matches_won }}/{{ row.grass_matches_lost }}
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 11"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container *ngIf="row.grass_competitions_played; else elseTmp">
                                {{ row.grass_competitions_won }}/{{ row.grass_competitions_lost }}
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 12"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container
                                *ngIf="
                                    row.carpet_indoor_matches_played ||
                                        row.unknown_matches_played ||
                                        row.hard_court_matches_played ||
                                        row.synthetic_indoor_matches_played;
                                    else elseTmp
                                "
                            >
                                {{
                                    +row.carpet_indoor_matches_won +
                                        +row.unknown_matches_won +
                                        +row.synthetic_indoor_matches_won +
                                        +row.hard_court_matches_won
                                }}/{{
                                    +row.carpet_indoor_matches_lost +
                                        +row.unknown_matches_lost +
                                        +row.synthetic_indoor_matches_lost +
                                        +row.hard_court_matches_lost
                                }}
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                        <td
                            class="table-scrollable__table-td table-scrollable__data__table-td"
                            (mouseenter)="activeRow = 13"
                            (mouseleave)="activeRow = 0"
                        >
                            <ng-container
                                *ngIf="
                                    row.carpet_indoor_competitions_played ||
                                        row.unknown_competitions_played ||
                                        row.hard_court_competitions_played ||
                                        row.synthetic_indoor_competitions_played;
                                    else elseTmp
                                "
                            >
                                {{
                                    +row.carpet_indoor_competitions_won +
                                        +row.unknown_competitions_won +
                                        +row.synthetic_indoor_competitions_won +
                                        +row.hard_court_competitions_won
                                }}/{{
                                    +row.carpet_indoor_competitions_lost +
                                        +row.unknown_competitions_lost +
                                        +row.synthetic_indoor_competitions_lost +
                                        +row.hard_court_competitions_lost
                                }}
                            </ng-container>
                            <ng-template #elseTmp> - </ng-template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
