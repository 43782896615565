<section class="participant-stats mt-2">
    <header class="participant-stats__header">{{ 'web.participant_stats_statistics_indicators' | translate }}</header>

    <ng-container *ngIf="normalTime">
        <div class="participant-stats__box">
            <div class="row">
                <div class="col-12 col-md-6" *ngFor="let stat of normalizeData; trackBy: trackByIndex">
                    <div class="participant-stats__item-parent">
                        <div class="d-flex justify-content-between p-2 participant-stats__item">
                            <div class="font-weight-bold">{{ 'web.pstats_' + stat.name | translate }}</div>
                            <div class="font-weight-bold">{{ stat.total / total | number: '1.1-1' }}</div>
                        </div>
                        <div class="participant-stats__item p-2">
                            <div class="d-flex">
                                <div class="no-square text-left flex-grow-1">
                                    {{ stat.homeTotal / totalHome | number: '1.1-1' }}
                                </div>
                                <div class="no-square text-center">
                                    {{ 'web.home' | translate }}/{{ 'web.away' | translate }}
                                </div>
                                <div class="no-square text-right flex-grow-1">
                                    {{ stat.awayTotal / totalAway | number: '1.1-1' }}
                                </div>
                            </div>
                            <div class="d-flex mb-3">
                                <div
                                    class="--bar --bar--win --bar--small text-center"
                                    [style.width.%]="
                                        (stat.homeTotal /
                                            totalHome /
                                            (stat.homeTotal / totalHome + stat.awayTotal / totalAway)) *
                                        100
                                    "
                                >
                                    &nbsp;
                                </div>
                                <div
                                    class="--bar --bar--draw --bar--small text-center"
                                    [style.width.%]="
                                        (stat.awayTotal /
                                            totalAway /
                                            (stat.homeTotal / totalHome + stat.awayTotal / totalAway)) *
                                        100
                                    "
                                >
                                    &nbsp;
                                </div>
                            </div>
                            <div class="d-flex justify-content-between participant-stats__item">
                                <div class="">{{ 'web.total' | translate }}</div>
                                <div class="font-weight-bold">{{ stat.total }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</section>
