/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable camelcase */
/**
 *  Match list item
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    HostBinding,
    ChangeDetectionStrategy,
    NgZone,
    OnDestroy,
    ChangeDetectorRef,
} from '@angular/core';
import { MatchService } from 'src/app/services/match.service';
import * as _ from 'underscore';
import { STATE_ANIMATION } from '@shared/animations/state.animation';
import { trigger } from '@angular/animations';
import SPORT_CONFIG from 'src/app/config/sport.config';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import $ from 'src/app/shared/jquery';
import { SCORE_BACK_ANIMATION } from '@shared/animations/score-back.animation';
import { of, Subscription } from 'rxjs';

import { MatchData, MatchState } from '@interfaces/match-data.interface';


import { ClientService } from '@services/client.service';

import { TranslateService } from '@ngx-translate/core';

import { delay } from 'rxjs/operators';


import { UtilsService } from '@services/utils.service';

import { PageService } from '@services/page.service';
import { WindowRefService } from '@shared/window-ref';

import { BaseComponent } from '../../base.component';
import { environment } from '../../../../environments/environment';

declare const moment: any;


@Component({
    selector: 'app-component-match',
    templateUrl: './match.component.html',
    providers: [MatchService],
    animations: [trigger('state', STATE_ANIMATION), trigger('score', SCORE_BACK_ANIMATION)],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
    @HostBinding('@.disabled')
    public animationsDisabled = true;

    @Input()
    public match: MatchData | null = null;

    @Input()
    public displayOdds: boolean = true;

    @Input()
    public widgetView: boolean = false;

    @Input()
    public sportName: string;

    @Input()
    public sportId: number;

    @Input()
    public page: string = 'match-list';

    @Input()
    public dateFormat: string = 'localTime';

    @Input()
    public makeNoise: boolean = true;

    @Input()
    public dateFormat2: string = 'localDateShort';

    @Input()
    public participantId: number | null = null;

    @Input()
    public matchActions: any;

    @Input()
    public showFavorite: boolean = false;

    @Input()
    public scoreActive = true;

    @Input()
    public playerStatistics: Record<string, any> | null = null;

    @Input()
    public substitutions: Record<string, any>[] | null = null;

    public xxx: boolean = false;

    public sportConfig: any;

    public isTablet: boolean;

    public act: boolean = false;

    public subscription: Subscription;

    private statusSets = { homeSet: false, awaySet: false, homeMatch: false, awayMatch: false };

    private updatingSets = false;

    public possibleGoal = false;


    /**
     * Output selected match id
     */
    @Output()
    public onSelect: EventEmitter<Record<string, any>> = new EventEmitter();

    public constructor(
        private matchService: MatchService,
        public deviceService: DeviceDetectorService,
        private router: Router,
        private ngZone: NgZone,
        public pageService: PageService,
        public ref: ChangeDetectorRef,
        public client: ClientService,
        private translate: TranslateService,
        protected win: WindowRefService,
    ) {
        super();
        this.sportConfig = SPORT_CONFIG;
    }

    public ngOnInit(): void {
        this.matchService.sportName = this.sportName;
        this.isTablet = this.deviceService.isTablet();

        this.subscription = this.pageService.onMatchActive.subscribe((val) => {
            if (this.match!.id === val) {
                this.act = true;
            } else {
                this.act = false;
            }
            this.ref.markForCheck();
        });

        setTimeout(() => {
            $('[data-toggle="tooltip"]').tooltip();
        });
    }


    public get isBefore(): boolean {
        return this.match!.match_state === MatchState.BEFORE;
    }

    public get isAfter(): boolean {
        return this.match!.match_state === MatchState.AFTER;
    }

    public get isOddsView(): boolean {
        if (this.client.has('bet', 'isOddsOnlyBeforeAndAfter')) {
            return !!((this.isBefore || this.isAfter) && this.match!.odds && this.isMobile && this.displayOdds);
        }
        return !!(this.match!.odds && this.isMobile && this.displayOdds);
    }

    public get isMobile(): boolean {
        return this.deviceService.isMobile();
    }

    public get isLive(): boolean {
        return (
            this.match?.match_state === MatchState.INPLAY ||
            this.match?.match_state === MatchState.INPLAYPAUSE
        );
    }

    get isHomeBatting(): boolean {
        const inningLenght = this.match?.innings?.length ?? 0;
        const actualInning = this.match?.innings?.[inningLenght - 1] ?? null;

        if (this.sportConfig.isCricket(this.sportName) &&
        this.match?.toss_won_by && this.match.toss_decision && actualInning && this.isLive) {
            const isFirstOnBat = (this.match?.toss_won_by === this.match!.participants[0]!.id &&
                this.match?.toss_decision === 'bat') ||
            (this.match?.toss_won_by === this.match!.participants[1]!.id && this.match?.toss_decision === 'bowl');


            if (isFirstOnBat && actualInning.number % 2 !== 0) {
                return true;
            }

            if (!isFirstOnBat && actualInning.number % 2 === 0) {
                return true;
            }
        }

        return false;
    }

    get isAwayBatting(): boolean {
        const inningLenght = this.match?.innings?.length ?? 0;
        const actualInning = this.match?.innings?.[inningLenght - 1] ?? null;

        if (
            this.sportConfig.isCricket(this.sportName) &&
            this.match?.toss_won_by && this.match?.toss_decision && actualInning && this.isLive) {
            const isFirstOnBat = (this.match?.toss_won_by === this.match!.participants[1]!.id &&
                this.match?.toss_decision === 'bat') ||
                (this.match?.toss_won_by === this.match!.participants[0]!.id && this.match?.toss_decision === 'bowl');

            if (isFirstOnBat && actualInning.number % 2 !== 0) {
                return true;
            }

            if (!isFirstOnBat && actualInning.number % 2 === 0) {
                return true;
            }
        }

        return false;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        // Possible goal
        if (changes.match) {
            const { match: { currentValue = null }, match: { previousValue = null } } = changes;

            if (this.match?.possibleGoal === null) {
                this.possibleGoal = false;
            }

            if (this.match?.possibleGoal && currentValue?.possibleGoal?.id !== previousValue?.possibleGoal?.id) {
                const possibleGoalTime = moment(this.match.possibleGoal.time);
                const nowTime = moment.utc();
                const diffTime = nowTime.diff(possibleGoalTime, 'seconds');

                if (diffTime < SPORT_CONFIG.possibelGoalTimeout) {
                    this.possibleGoal = true;
                    of(true).pipe(delay(20000)).subscribe(() => {
                        this.possibleGoal = false;
                        this.ref.markForCheck();
                    });
                }
            }
        }

        // eslint-disable-next-line camelcase
        this.matchService.state = changes.match?.currentValue?.match_state ?? this.matchService.state;


        // this.ngZone.runOutsideAngular((): void => {
        // setTimeout((): void => {
        //     // this.ngZone.run((): void => {
        //     this.scoreActive = true;
        //     this.ref.markForCheck();
        // }, 0);
        // });
        // });

        if (changes.match && !_.isUndefined(changes.match.previousValue)) {
            this.animationsDisabled = false;

            if (this.isSet) {
                const homeWin = changes.match.currentValue.score.home_team >
                    changes.match.previousValue.score.home_team;
                const awayWin = changes.match.currentValue.score.away_team >
                    changes.match.previousValue.score.away_team;

                if (!this.updatingSets) {
                    this.statusSets.homeSet = !this.isAfter && homeWin;
                    this.statusSets.awaySet = !this.isAfter && awayWin;
                    this.statusSets.homeMatch = this.isAfter && homeWin;
                    this.statusSets.awayMatch = this.isAfter && awayWin;
                }

                if (this.statusSets.homeSet || this.statusSets.awaySet ||
                    this.statusSets.homeMatch || this.statusSets.awayMatch) {
                    this.updatingSets = true;
                    UtilsService.requestTimeout(() => {
                        _.forEach(this.statusSets, (value, key) => {
                            // @ts-ignore: TODO add type
                            this.statusSets[key] = false;
                        });
                        this.updatingSets = false;
                    }, environment.gameSetAnimation);
                }
            }
        }

        if (changes.match && !_.isUndefined(changes.match.previousValue) && !this.deviceService.isMobile()) {
            this.matchService.handleScoreChange(changes.match.currentValue.score, changes.match.previousValue.score, {
                makeNoise: this.makeNoise,
            });

            this.matchService.handleStateChange(
                changes.match.currentValue.code_state,
                changes.match.previousValue.code_state,
                { makeNoise: this.makeNoise },
            );
        }
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onIconEvent(event: string): void {
        this.win.nativeWindow.dataLayer.push({
            event: 'click_icon',
            icon_name: event,
            icon_sport: this.sportName,
            icon_league: this.match?.sub_tournament_name,
            icon_match: `match_id_${this.match?.id}`,
        });
    }

    public get oneline(): boolean {
        return SPORT_CONFIG.displayType.sets.includes(this.sportName) === false && !this.isMobile && !this.widgetView;
    }

    public get imageShow(): boolean {
        return !SPORT_CONFIG.displayType.noLogo.includes(this.sportName);
    }

    get imageHome(): string | false | null {
        if (SPORT_CONFIG.displayType.noLogo.includes(this.sportName)) {
            return this.match!.participants[0]?.countryImage ?? this.match!.participants[0]?.image ?? false;
        }
        return this.match!.participants[0]?.image ?? false;
    }

    get imageAway(): string | false | null {
        if (SPORT_CONFIG.displayType.noLogo.includes(this.sportName)) {
            return this.match!.participants[1]?.countryImage ?? this.match!.participants[1]?.image ?? false;
        }
        return this.match!.participants[1]?.image ?? false;
    }

    /**
     * Check if home team is winner
     * @return {boolean}
     */
    public isWinnerHome(): boolean {
        return (
            (this.match!.match_state === 'after' || this.match!.match_state === 'cancelled') &&
                (
                    (this.match?.winner === 1 && (this.match?.aggregate_winner === 1 ||
                    this.match?.aggregate_winner === null || this.match?.aggregate_winner === 0)) ||
            (this.match?.winner === 2 && this.match?.aggregate_winner === 1)
                )
        );
    }

    /**
     * Check if away team is winner
     * @return {boolean}
     */
    public isWinnerAway(): boolean {
        return (
            (this.match!.match_state === 'after' || this.match!.match_state === 'cancelled') &&
            (
                (this.match?.winner === 2 && (this.match?.aggregate_winner === 2 ||
                    this.match?.aggregate_winner === null || this.match?.aggregate_winner === 0)) ||
            (this.match?.winner === 1 && this.match?.aggregate_winner === 2)
            )
        );
    }


    get scoreSeries(): false | number[] {
        if (!this.match!.score?.series_score || this.match!.score?.series_score.length === 0 || this.isAfter ||
            !SPORT_CONFIG.score.seriesScore.includes(this.sportName)) {
            return false;
        }

        return this.match!.score?.series_score;
    }

    get scoreSeriesText(): string {
        return `${this.translate.instant('web.score_serie')} ${this.match!.score?.series_score!.join(':')}`;
    }

    public get setChange(): boolean {
        return this.statusSets.homeSet || this.statusSets.awaySet ||
                    this.statusSets.homeMatch || this.statusSets.awayMatch;
    }

    public get setClass(): Record<string, boolean> {
        return { 'tennis-set-match--home': this.statusSets.homeSet || this.statusSets.homeMatch,
            'tennis-set-match--away': this.statusSets.awaySet || this.statusSets.awayMatch,
        };
    }

    public get setText(): string {
        if (this.statusSets.homeSet || this.statusSets.awaySet) {
            return 'end_set';
        }

        return 'end_set_match';
    }

    public get isSet(): boolean {
        return SPORT_CONFIG.isTennis(this.sportName) ||
        SPORT_CONFIG.isBadminton(this.sportName) ||
        SPORT_CONFIG.isSquash(this.sportName);
    }

    public get hashead2Head(): boolean {
        return SPORT_CONFIG.hash2h(this.sportName);
    }

    get isActive(): boolean {
        return this.act;
    }

    get isTrackerSet(): boolean {
        return !!(this.match?.tracker) && this.client.use('tracker');
    }

    public active(tracker: boolean = false, stream: boolean = false): void {
        this.pageService.onMatchActive.next(true);
        this.act = true;
        const mql = window.matchMedia('(max-width: 990px)');

        if (this.isMobile || this.isTablet || this.widgetView || mql.matches) {
            this.ngZone.runOutsideAngular((): void => {
                setTimeout((): void => {
                    this.ngZone.run((): void => {
                        this.router.navigate([`/page/sport/match/${this.sportName}-${this.sportId}/${this.match!.id}`,
                            { match_state: this.match?.match_state }],
                        );
                    });
                }, 500);
            });
            return;
        }

        const Backup = this.match!.minute;

        this.match!.minute = null;
        window.requestAnimationFrame((): void => {
            this.match!.minute = Backup;
        });

        this.onSelect.emit({ id: this.match!.id, tracker, stream, match_state: this.match?.match_state });
    }

    /**
     * Show favorite
     * @return {boolean}
     */
    public get isFavoriteEnabled(): boolean {
        return (this.match!.code_state !== 'postponed' && this.match!.match_state !== 'after') || this.showFavorite;
    }

    public get hasStream(): string | number | undefined {
        return (
            this.match!.twitch_video ||
            this.match!.twitch_channel ||
            this.match!.youtube_video ||
            this.match!.nhl_video ||
            this.match!.isport_video ||
            this.match!.tvcom_video ||
            this.match!.vimeo_video
        );
    }

    get isLiveStrem(): boolean {
        return !!(this.match!.tvcom_video);
    }

    public isOlderThan(days: number = 2): boolean {
        const StartDate = moment(this.match!.start_date);
        const Diff: number = moment().diff(StartDate, 'days') as number;
        return Diff > days && this.page !== 'match-list';
    }
}
