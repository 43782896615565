<div *ngIf="playerStatistics || matchActions" class="table-sport__cell table-sport__cell--info">
    <span
        *ngIf="+playerStatistics?.GoalsScored > 0 || matchActions?.GOAL"
        class="circle-info"
        data-toggle="tooltip"
        data-placement="top"
        title="{{ 'web.goal' | translate }}"
    >
        <img src="img/sports/{{ sportName }}.png" alt="{{ 'sport.' + sportName | translate }}" width="14" />
        <span
            *ngIf="+playerStatistics?.GoalsScored > 1 || matchActions?.GOAL?.length > 1"
            class="badge badge-primary rounded-circle"
            >{{ playerStatistics?.GoalsScored || matchActions.GOAL.length }}</span
        >
    </span>
    <span
        *ngIf="pointsGoal && pointsGoal > 0"
        class="circle-info"
        data-toggle="tooltip"
        data-placement="top"
        title="{{ 'web.' + sportName + '_goal' | translate }}"
    >
        <img src="img/sports/{{ sportName }}.png" alt="{{ 'sport.' + sportName | translate }}" width="14" />
        <span *ngIf="pointsGoal > 1" class="badge badge-primary rounded-circle">{{ pointsGoal }}</span>
    </span>
    <span
        *ngIf="playerStatistics?.PlusMinus && playerStatistics?.PlusMinus != 0"
        class="circle-info"
        data-toggle="tooltip"
        data-placement="top"
        title="{{ 'web.plus_minus' | translate }}"
        >+/-
        <span class="badge badge-primary rounded-circle">{{ playerStatistics?.PlusMinus }}</span>
    </span>
    <span
        *ngIf="(playerStatistics?.Assists && playerStatistics?.Assists != 0) || matchActions?.ASSIST"
        class="circle-info"
        data-toggle="tooltip"
        data-placement="top"
        title="{{ 'web.assistance' | translate }}"
        >A
        <span
            *ngIf="+playerStatistics?.Assists > 1 || matchActions?.ASSIST?.length > 1"
            class="badge badge-primary rounded-circle"
            >{{ playerStatistics?.Assists || matchActions.ASSIST.length }}</span
        >
    </span>
    <span
        *ngIf="(playerStatistics?.YellowCards && playerStatistics?.YellowCards != 0) || matchActions?.YELCARD"
        class="circle-info"
        data-toggle="tooltip"
        data-placement="top"
        title="{{ 'web.yellow_card' | translate }}"
    >
        <svg class="icon icon--12 icon--yellow" id="yellow-card"><use href="#icon-card-yellow" /></svg>
    </span>
    <span
        *ngIf="redCardStats || matchActions?.REDCARD || matchActions?.YELLOW_RED_CARD"
        class="circle-info"
        data-toggle="tooltip"
        data-placement="top"
        title="{{ 'web.red_card' | translate }}"
    >
        <svg class="icon icon--12 icon--danger" id="red-card"><use href="#icon-card-yellow" /></svg>
    </span>
    <span
        *ngIf="playedTime !== false"
        class="circle-info"
        [class.circle-info--up]="playerStatistics?.SubstitutedIn == 1"
        [class.circle-info--down]="playerStatistics?.SubstitutedOut == 1"
        data-toggle="tooltip"
        data-placement="top"
        title="{{ 'web.field_time' | translate }}"
    >
        {{ playedTime }}'
    </span>
    <!-- <span
        *ngIf="
            !playerStatistics?.MinutesPlayed &&
            (playerStatistics?.SubstitutedIn == 1 || playerStatistics?.SubstitutedOut == 1)
        "
        class="circle-info"
        [class.circle-info--up]="playerStatistics?.SubstitutedIn == 1"
        [class.circle-info--down]="playerStatistics?.SubstitutedOut == 1"
        data-toggle="tooltip"
        data-placement="top"
        title="{{ 'web.substitution' | translate }}"
    >
        {{ substitutedIn ? substitutedIn : substitutedOut ? substitutedOut : '-' }}
    </span> -->
    <ng-container *ngIf="matchActions?.SUBS">
        <span
            *ngFor="let matchAction of matchActions.SUBS"
            class="circle-info circle-info--{{ matchAction.matchActionType === 'SUBS_UP' ? 'up' : 'down' }}"
            data-toggle="tooltip"
            data-placement="top"
            title="{{ 'web.substitution' | translate }}"
            >{{ matchAction.minute }}'</span
        >
    </ng-container>
</div>
