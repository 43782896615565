<div class="participant-autocomplete d-flex flex-column mt-3 mt-lg-0" data-cy="participant-comparition-box">
    <header class="participant-autocomplete__header">{{ 'web.participant_comapre_head' | translate }}</header>
    <div class="participant-autocomplete__item">
        <ng-container *ngIf="participantFirst === null; else firstSelected">
            <input
                matInput
                #first
                placeholder="{{ 'web.enter_' + who + '_first' | translate }}"
                type="search"
                autocomplete="off"
                data-cy="input-participant-one"
                class="form-control participant-autocomplete__field"
            />
            <div class="participant-autocomplete__items" data-cy="search-result">
                <div
                    class="--items-val"
                    *ngFor="let data of $first | async; trackBy: trackByIndex"
                    (click)="selectParticipant('first', data)"
                >
                    <img class="mr-2" height="20" [src]="data.image" [alt]="data.name" />
                    {{ data.name }}
                    <ng-container *ngIf="data.tournaments && data.tournaments!.length > 0">
                        <small>
                            - {{ data.tournaments[0].sub_tournament_name }} ({{ data.tournaments[0].category_name }})
                        </small>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <ng-template #firstSelected>
            <div class="participant-autocomplete__selected">
                <img class="mr-2" height="20" [src]="participantFirst?.image" [alt]="participantFirst?.name" />
                {{ participantFirst?.name }}
                <ng-container *ngIf="participantFirst?.tournaments && (participantFirst?.tournaments)!.length > 0">
                    <small>
                        - {{ participantFirst.tournaments[0].sub_tournament_name }} ({{
                            participantFirst.tournaments[0].category_name
                        }})
                    </small>
                </ng-container>
                &nbsp;&nbsp;&nbsp;&nbsp;<i class="fas fa-times alert-color app-cursor" (click)="resetVal('first')"></i>
            </div>
        </ng-template>
    </div>
    <div class="participant-autocomplete__item">
        <ng-container *ngIf="participantSecond === null; else secondSelected">
            <input
                matInput
                #second
                placeholder="{{ 'web.enter_' + who + '_second' | translate }}"
                type="search"
                autocomplete="off"
                data-cy="input-participant-two"
                class="form-control participant-autocomplete__field"
            />
            <div class="participant-autocomplete__items" data-cy="search-result">
                <div
                    class="--items-val"
                    *ngFor="let data of $second | async; trackBy: trackByIndex"
                    (click)="selectParticipant('second', data)"
                >
                    <img class="mr-2" height="20" [src]="data.image" [alt]="data.name" />
                    {{ data.name }}
                    <ng-container *ngIf="data.tournaments && data.tournaments!.length > 0">
                        <small>
                            - {{ data.tournaments[0].sub_tournament_name }} ({{ data.tournaments[0].category_name }})
                        </small>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <ng-template #secondSelected>
            <div class="participant-autocomplete__selected">
                <img class="mr-2" height="20" [src]="participantSecond?.image" [alt]="participantSecond?.name" />
                {{ participantSecond?.name }}
                <ng-container *ngIf="participantSecond?.tournaments && (participantSecond?.tournaments)!.length > 0">
                    <small>
                        - {{ participantSecond.tournaments[0].sub_tournament_name }} ({{
                            participantSecond.tournaments[0].category_name
                        }})
                    </small>
                </ng-container>
                &nbsp;&nbsp;&nbsp;&nbsp;<i class="fas fa-times alert-color app-cursor" (click)="resetVal('second')"></i>
            </div>
        </ng-template>
    </div>
    <button
        data-cy="button-search"
        class="btn btn-primary btn-block rounded-0 mt-3"
        [disabled]="!isValid"
        (click)="doCompare()"
    >
        <ng-container *ngIf="!loading; else load"> {{ 'web.search_compare' | translate }} </ng-container>
        <ng-template #load> {{ 'web.searching' | translate }} ... </ng-template>
    </button>
</div>
