<section class="participant-stats mt-2">
    <header class="participant-stats__header">{{ 'web.participant_stats_uo' | translate }}</header>

    <ng-container *ngIf="normalTime">
        <div class="participant-stats__box">
            <div class="row">
                <div class="col-12 col-md-12">
                    <div class="participant-stats__item-parent">
                        <div class="d-flex justify-content-between p-2 participant-stats__item">
                            <div class="font-weight-bold --bar-text --bar-text--win">{{ 'web.over' | translate }}</div>
                            <div class="font-weight-bold">{{ 'web.matches' | translate }}</div>
                            <div class="font-weight-bold --bar-text --bar-text--draw">
                                {{ 'web.under' | translate }}
                            </div>
                        </div>
                        <div
                            class="participant-stats__item p-2"
                            *ngFor="let val of this.normalTime.uo | keys; trackBy: trackByIndex"
                        >
                            <div class="d-flex">
                                <div class="no-square text-left flex-grow-1">
                                    {{ val.key }}
                                </div>
                                <div class="no-square text-center">
                                    <small>({{ val.value.overPrct }}%)</small
                                    ><span class="participant-stats__total-half--win">{{ val.value.over }}</span
                                    >/<span class="participant-stats__total-half--draw">{{ val.value.under }}</span
                                    ><small>({{ val.value.underPrct }}%)</small>
                                </div>
                                <div class="no-square text-right flex-grow-1">
                                    {{ val.key }}
                                </div>
                            </div>
                            <div class="d-flex mb-3">
                                <div
                                    class="--bar --bar--win --bar--small text-center"
                                    [style.width.%]="val.value.overPrct"
                                >
                                    &nbsp;
                                </div>
                                <div
                                    class="--bar --bar--draw --bar--small text-center"
                                    [style.width.%]="val.value.underPrct"
                                >
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6 col-lg-4 font-weight-bold">{{ 'web.both_teams_score_in_match' | translate }}</div>
                <div class="col-6 col-lg-2 text-right">
                    <span class="participant-stats__total-half--win">{{ normalTime.bothTeamScorePrct }}%</span>
                    <br />
                    <span class="--small">
                        {{ normalTime.bothTeamScore }} {{ 'web.of' | translate }} {{ normalTime.total }}
                    </span>
                </div>
                <div class="col-6 col-lg-4 font-weight-bold">{{ 'web.matches_with_no_goal' | translate }}</div>
                <div class="col-6 col-lg-2 text-right">
                    <span class="participant-stats__total-half--win">{{ normalTime.noGoalPrct }}%</span>
                    <br />
                    <span class="--small">
                        {{ normalTime.noGoal }} {{ 'web.of' | translate }} {{ normalTime.total }}
                    </span>
                </div>
                <div class="col-6 col-lg-4 font-weight-bold">{{ 'web.team_average_total' | translate }}</div>
                <div class="col-6 col-lg-2 text-right">
                    <span class="participant-stats__total-half--win">{{
                        normalTime.sumGoalsAverage | number: '1.0-1'
                    }}</span>
                </div>
            </div>
        </div>
    </ng-container>
</section>
