<section class="participant-stats mt-2">
    <header class="participant-stats__header">{{ 'web.participant_stats_scored_goals' | translate }}</header>

    <ng-container *ngIf="normalTime">
        <div class="participant-stats__box">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="participant-stats__item-parent">
                        <div class="d-flex justify-content-between p-2 participant-stats__item">
                            <div class="font-weight-bold">{{ 'web.total_goals' | translate }}</div>
                            <div class="font-weight-bold">{{ normalTime!.goalsTotal }}</div>
                        </div>
                        <div class="participant-stats__item p-2">
                            <div class="d-flex">
                                <div class="no-square text-left flex-grow-1">
                                    {{ normalTime!.goalsHome }}
                                </div>
                                <div class="no-square text-center">
                                    {{ 'web.home' | translate }}/{{ 'web.away' | translate }}
                                </div>
                                <div class="no-square text-right flex-grow-1">
                                    {{ normalTime!.goalsAway }}
                                </div>
                            </div>
                            <div class="d-flex mb-3">
                                <div
                                    class="--bar --bar--win --bar--small text-center"
                                    [style.width.%]="normalTime.goalsHomePrct"
                                >
                                    &nbsp;
                                </div>
                                <div
                                    class="--bar --bar--draw --bar--small text-center"
                                    [style.width.%]="normalTime.goalsAwayPrct"
                                >
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                        <div
                            *ngIf="avgMinFirstGoalTotal"
                            class="d-flex justify-content-between p-2 participant-stats__item"
                        >
                            <div class="">{{ 'web.average_minute_first_goal' | translate }}</div>
                            <div class="font-weight-bold">{{ avgMinFirstGoalTotal }}'</div>
                        </div>
                        <div
                            *ngIf="avgMinFirstGoalTotalHome || avgMinFirstGoalTotalAway"
                            class="d-flex p-2 participant-stats__item"
                        >
                            <div class="--bar-text--win-text no-square text-left flex-grow-1 font-weight-bold">
                                {{ avgMinFirstGoalTotalHome ? avgMinFirstGoalTotalHome + "'" : '-' }}
                            </div>
                            <div class="no-square text-center">
                                {{ 'web.home' | translate }}/{{ 'web.away' | translate }}
                            </div>
                            <div class="--bar-text--draw-text no-square text-right flex-grow-1 font-weight-bold">
                                {{ avgMinFirstGoalTotalAway ? avgMinFirstGoalTotalAway + "'" : '-' }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="participant-stats__item-parent">
                        <div class="d-flex justify-content-between p-2 participant-stats__item">
                            <div class="font-weight-bold">{{ 'web.average_goal' | translate }}</div>
                            <div class="font-weight-bold">{{ normalTime!.goalsAverage }}</div>
                        </div>
                        <div class="participant-stats__item p-2">
                            <div class="d-flex">
                                <div class="no-square text-left flex-grow-1">
                                    {{ normalTime!.goalsAverageHome }}
                                </div>
                                <div class="no-square text-center">
                                    {{ 'web.home' | translate }}/{{ 'web.away' | translate }}
                                </div>
                                <div class="no-square text-right flex-grow-1">
                                    {{ normalTime!.goalsAverageAway }}
                                </div>
                            </div>
                            <div class="d-flex mb-3">
                                <div
                                    class="--bar --bar--win --bar--small text-center"
                                    [style.width.%]="normalTime!.goalsAverageHomePrct"
                                >
                                    &nbsp;
                                </div>
                                <div
                                    class="--bar --bar--draw --bar--small text-center"
                                    [style.width.%]="normalTime!.goalsAverageAwayPrct"
                                >
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                        <div *ngIf="avgMinGoalTotal" class="d-flex justify-content-between p-2 participant-stats__item">
                            <div class="">{{ 'web.average_time_for_goal' | translate }}</div>
                            <div class="font-weight-bold">{{ avgMinGoalTotal }}'</div>
                        </div>
                        <div
                            class="d-flex p-2 participant-stats__item"
                            *ngIf="avgMinGoalTotalHome || avgMinGoalTotalAway"
                        >
                            <div class="--bar-text--win-text no-square text-left flex-grow-1 font-weight-bold">
                                {{ avgMinGoalTotalHome ? avgMinGoalTotalHome + "'" : '-' }}
                            </div>
                            <div class="no-square text-center">
                                {{ 'web.home' | translate }}/{{ 'web.away' | translate }}
                            </div>
                            <div class="--bar-text--draw-text no-square text-right flex-grow-1 font-weight-bold">
                                {{ avgMinGoalTotalAway ? avgMinGoalTotalAway + "'" : '-' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-md-5">
                <div class="col-12 col-md-6">
                    <div class="participant-stats__item-parent">
                        <div class="d-flex justify-content-between p-2 participant-stats__item">
                            <div class="font-weight-bold">{{ 'web.total_concated_goals' | translate }}</div>
                            <div class="font-weight-bold">{{ normalTime!.goalsConcatedTotal }}</div>
                        </div>
                        <div class="participant-stats__item p-2">
                            <div class="d-flex">
                                <div class="no-square text-left flex-grow-1">
                                    {{ normalTime!.goalsConcatedHome }}
                                </div>
                                <div class="no-square text-center">
                                    {{ 'web.home' | translate }}/{{ 'web.away' | translate }}
                                </div>
                                <div class="no-square text-right flex-grow-1">
                                    {{ normalTime!.goalsConcatedAway }}
                                </div>
                            </div>
                            <div class="d-flex mb-3">
                                <div
                                    class="--bar --bar--win --bar--small text-center"
                                    [style.width.%]="normalTime.goalsConcatedHomePrct"
                                >
                                    &nbsp;
                                </div>
                                <div
                                    class="--bar --bar--draw --bar--small text-center"
                                    [style.width.%]="normalTime.goalsConcatedAwayPrct"
                                >
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                        <div
                            *ngIf="avgMinFirstGoalTotalConcated"
                            class="d-flex justify-content-between p-2 participant-stats__item"
                        >
                            <div class="">{{ 'web.average_minute_first_goal_concated' | translate }}</div>
                            <div class="font-weight-bold">{{ avgMinFirstGoalTotalConcated }}'</div>
                        </div>
                        <div
                            *ngIf="avgMinFirstGoalTotalHomeConcated || avgMinFirstGoalTotalAwayConcated"
                            class="d-flex p-2 participant-stats__item"
                        >
                            <div class="--bar-text--win-text no-square text-left flex-grow-1 font-weight-bold">
                                {{ avgMinFirstGoalTotalHomeConcated ? avgMinFirstGoalTotalHomeConcated + "'" : '-' }}
                            </div>
                            <div class="no-square text-center">
                                {{ 'web.home' | translate }}/{{ 'web.away' | translate }}
                            </div>
                            <div class="--bar-text--draw-text no-square text-right flex-grow-1 font-weight-bold">
                                {{ avgMinFirstGoalTotalAwayConcated ? avgMinFirstGoalTotalAwayConcated + "'" : '-' }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="participant-stats__item-parent">
                        <div class="d-flex justify-content-between p-2 participant-stats__item">
                            <div class="font-weight-bold">{{ 'web.average_time_for_concated_goal' | translate }}</div>
                            <div class="font-weight-bold">{{ normalTime!.goalsConcatedAverage }}</div>
                        </div>
                        <div class="participant-stats__item p-2">
                            <div class="d-flex">
                                <div class="no-square text-left flex-grow-1">
                                    {{ normalTime!.goalsConcatedAverageHome }}
                                </div>
                                <div class="no-square text-center">
                                    {{ 'web.home' | translate }}/{{ 'web.away' | translate }}
                                </div>
                                <div class="no-square text-right flex-grow-1">
                                    {{ normalTime!.goalsConcatedAverageAway }}
                                </div>
                            </div>
                            <div class="d-flex mb-3">
                                <div
                                    class="--bar --bar--win --bar--small text-center"
                                    [style.width.%]="normalTime.goalsConcatedAverageHomePrct"
                                >
                                    &nbsp;
                                </div>
                                <div
                                    class="--bar --bar--draw --bar--small text-center"
                                    [style.width.%]="normalTime.goalsConcatedAverageAwayPrct"
                                >
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                        <div
                            *ngIf="avgMinGoalTotalConcated"
                            class="d-flex justify-content-between p-2 participant-stats__item"
                        >
                            <div class="">{{ 'web.average_time_for_goal_concated' | translate }}</div>
                            <div class="font-weight-bold">{{ avgMinGoalTotalConcated }}'</div>
                        </div>
                        <div
                            class="d-flex p-2 participant-stats__item"
                            *ngIf="avgMinGoalTotalHomeConcated || avgMinGoalTotalAwayConcated"
                        >
                            <div class="--bar-text--win-text no-square text-left flex-grow-1 font-weight-bold">
                                {{ avgMinGoalTotalHomeConcated ? avgMinGoalTotalHomeConcated + "'" : '-' }}
                            </div>
                            <div class="no-square text-center">
                                {{ 'web.home' | translate }}/{{ 'web.away' | translate }}
                            </div>
                            <div class="--bar-text--draw-text no-square text-right flex-grow-1 font-weight-bold">
                                {{ avgMinGoalTotalAwayConcated ? avgMinGoalTotalAwayConcated + "'" : '-' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</section>
