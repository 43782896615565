/* eslint-disable eqeqeq */
import { SportConfigToken } from '@config/sport.config';
import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'app-match-action-icons',
    templateUrl: './match-action-icons.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchActionIconsComponent implements OnChanges {
    @Input()
    public matchActions: any;

    @Input()
    public sportName: string;

    @Input()
    public playerStatistics: Record<string, any> | null = null;

    @Input()
    public substitutions: Record<string, any>[] | null = null;

    public constructor(@Inject(SportConfigToken) private sportConfig: Record<string, any>) {

    }

    public ngOnChanges(): void {
        if (this.playerStatistics) {
            this.matchActions = null;
        }
    }

    get redCardStats(): boolean {
        return (+this.playerStatistics?.RedCards + +this.playerStatistics?.YellowRedCards) > 0;
    }

    get pointsGoal(): number | false {
        if (!this.playerStatistics?.Points || (!this.isBasketball && !this.playerStatistics?.Assists)) {
            return false;
        }

        return +this.playerStatistics?.Points - (this.isBasketball ? 0 : this.playerStatistics?.Assists);
    }

    get substitutedIn(): number | false {
        const sub = this.substitutions?.find(s => s.type === 'substituted_in');

        if (!sub) {
            return false;
        }

        return sub.match_time;
    }

    get substitutedOut(): number | false {
        const sub = this.substitutions?.find(s => s.type === 'substituted_out');

        if (!sub) {
            return false;
        }

        return sub.match_time;
    }

    get isBasketball(): boolean {
        return this.sportConfig.isBasketball(this.sportName);
    }

    get playedTime(): number | string | false {
        if (this.playerStatistics?.MinutesPlayed && (this.playerStatistics?.SubstitutedOut == 1 ||
            (this.playerStatistics?.SubstitutedOut == 0 || this.playerStatistics?.SubstitutedIn == 1))) {
            return this.playerStatistics?.MinutesPlayed;
        }

        if (this.substitutedIn || this.substitutedOut) {
            return this.substitutedIn ? this.substitutedIn : this.substitutedOut;
        }

        if (this.matchActions?.FIELD_TIME) {
            return this.matchActions?.FIELD_TIME;
        }

        if (this.playerStatistics?.Starter == 1 && this.playerStatistics?.SubstitutedOut == 0 &&
            this.playerStatistics?.SubstitutedIn == 0) {
            return 90;
        }

        return false;
    }
}
