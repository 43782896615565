<section *ngIf="hasTimelineData" class="participant-stats mt-2">
    <header class="participant-stats__header">{{ 'web.participant_stats_scored_concated_goals' | translate }}</header>

    <ng-container *ngIf="normalTime">
        <!--SCORED GOALS -->
        <div class="participant-stats__box">
            <div class="row">
                <div class="col-12">
                    <div class="participant-stats__item-parent">
                        <div class="d-flex justify-content-between p-2 participant-stats__item">
                            <div class="font-weight-bold">{{ 'web.total_goals' | translate }}</div>
                        </div>
                        <div class="d-flex mb-3">
                            <div
                                class="participant-stats__minute-parent --bar --bar--win --bar--medium text-center flex-fill"
                                [ngClass]="lighter('total', '0-15')"
                            >
                                {{ total['0-15'] ? total['0-15'] : 0 }}
                                <div class="participant-stats__minute --left-minute">0`</div>
                                <div class="participant-stats__minute --right-minute">15`</div>
                            </div>
                            <div
                                class="--bar --bar--win --bar--medium text-center flex-fill"
                                [ngClass]="lighter('total', '16-30')"
                            >
                                {{ total['16-30'] ? total['16-30'] : 0 }}
                            </div>
                            <div
                                class="participant-stats__minute-parent --bar --bar--win --bar--medium text-center flex-fill"
                                [ngClass]="lighter('total', '31-45')"
                            >
                                {{ total['31-45'] ? total['31-45'] : 0 }}

                                <div class="participant-stats__minute --left-minute">30`</div>
                                <div class="participant-stats__minute --right-minute">45`</div>
                            </div>
                            <div class="--bar --bar--lose --bar--medium text-center flex-shrink-1">HT</div>
                            <div
                                class="participant-stats__minute-parent --bar --bar--win --bar--medium text-center flex-fill"
                                [ngClass]="lighter('total', '45-60')"
                            >
                                {{ total['45-60'] ? total['45-60'] : 0 }}
                                <div class="participant-stats__minute --left-minute">46`</div>
                                <div class="participant-stats__minute --right-minute">60`</div>
                            </div>
                            <div
                                class="--bar --bar--win --bar--medium text-center flex-fill"
                                [ngClass]="lighter('total', '61-75')"
                            >
                                {{ total['61-75'] ? total['61-75'] : 0 }}
                            </div>
                            <div
                                class="participant-stats__minute-parent --bar --bar--win --bar--medium text-center flex-fill"
                                [ngClass]="lighter('total', '76-90')"
                            >
                                {{ total['76-90'] ? total['76-90'] : 0 }}
                                <div class="participant-stats__minute --left-minute">75`</div>
                                <div class="participant-stats__minute --right-minute">90`</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-12">
                    <div class="participant-stats__item-parent mb-0">
                        <div class="d-flex justify-content-between p-2 participant-stats__item">
                            <div class="font-weight-bold --bar-text --bar-text--win">{{ 'web.home' | translate }}</div>
                            <div class="font-weight-bold">&nbsp;</div>
                            <div class="font-weight-bold --bar-text --bar-text--draw">
                                {{ 'web.away' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- HOME -->
                <div class="col-12 col-md-6">
                    <div class="participant-stats__item-parent mb-0" *ngFor="let time of times; trackBy: trackByIndex">
                        <div class="participant-stats__item p-2">
                            <div class="d-flex">
                                <div class="no-square text-left">
                                    <span class="participant-stats__total-half--neutral w100 app-no-wrap"
                                        >{{ time[0] + '-' + time[1] }}`</span
                                    >
                                </div>

                                <div
                                    class="--bar --bar--win --bar--filled text-center align-self-stretch ml-2 flex-grow-1"
                                    [style.width.%]="40"
                                >
                                    <div
                                        class="--bar--filled --bar--filled-active"
                                        [style.width.%]="getWidth('home', time[0] + '-' + time[1])"
                                    >
                                        {{ getWidth('home', time[0] + '-' + time[1]) }}%
                                    </div>
                                </div>
                                <div class="no-square text-center font-weight-bold">
                                    {{ home[time[0] + '-' + time[1]] ? home[time[0] + '-' + time[1]] : 0 }}x
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- AWAY -->
                <div class="col-12 col-md-6">
                    <div class="participant-stats__item-parent mb-0" *ngFor="let time of times; trackBy: trackByIndex">
                        <div class="participant-stats__item p-2">
                            <div class="d-flex">
                                <div class="no-square text-left">
                                    <span class="participant-stats__total-half--neutral w100 app-no-wrap"
                                        >{{ time[0] + '-' + time[1] }}`</span
                                    >
                                </div>

                                <div
                                    class="--bar --bar--win --bar--filled text-center align-self-stretch ml-2 flex-grow-1"
                                    [style.width.%]="40"
                                >
                                    <div
                                        class="--bar--filled --bar--filled-active2"
                                        [style.width.%]="getWidth('away', time[0] + '-' + time[1])"
                                    >
                                        {{ getWidth('away', time[0] + '-' + time[1]) }}%
                                    </div>
                                </div>
                                <div class="no-square text-center font-weight-bold">
                                    {{ away[time[0] + '-' + time[1]] ? away[time[0] + '-' + time[1]] : 0 }}x
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="normalTime">
        <!--CONCATED GOALS -->
        <div class="participant-stats__box mt-3">
            <div class="row">
                <div class="col-12">
                    <div class="participant-stats__item-parent">
                        <div class="d-flex justify-content-between p-2 participant-stats__item">
                            <div class="font-weight-bold">{{ 'web.total_concated_goals' | translate }}</div>
                        </div>
                        <div class="d-flex mb-3">
                            <div
                                class="participant-stats__minute-parent --bar --bar--win --bar--medium text-center flex-fill"
                                [ngClass]="lighter('totalConcated', '0-15')"
                            >
                                {{ totalConcated['0-15'] ? totalConcated['0-15'] : 0 }}
                                <div class="participant-stats__minute --left-minute">0`</div>
                                <div class="participant-stats__minute --right-minute">15`</div>
                            </div>
                            <div
                                class="--bar --bar--win --bar--medium text-center flex-fill"
                                [ngClass]="lighter('totalConcated', '16-30')"
                            >
                                {{ totalConcated['16-30'] ? totalConcated['16-30'] : 0 }}
                            </div>
                            <div
                                class="participant-stats__minute-parent --bar --bar--win --bar--medium text-center flex-fill"
                                [ngClass]="lighter('totalConcated', '31-45')"
                            >
                                {{ totalConcated['31-45'] ? totalConcated['31-45'] : 0 }}

                                <div class="participant-stats__minute --left-minute">30`</div>
                                <div class="participant-stats__minute --right-minute">45`</div>
                            </div>
                            <div class="--bar --bar--lose --bar--medium text-center flex-shrink-1">HT</div>
                            <div
                                class="participant-stats__minute-parent --bar --bar--win --bar--medium text-center flex-fill"
                                [ngClass]="lighter('totalConcated', '45-60')"
                            >
                                {{ totalConcated['45-60'] ? totalConcated['45-60'] : 0 }}
                                <div class="participant-stats__minute --left-minute">46`</div>
                                <div class="participant-stats__minute --right-minute">60`</div>
                            </div>
                            <div
                                class="--bar --bar--win --bar--medium text-center flex-fill"
                                [ngClass]="lighter('totalConcated', '61-75')"
                            >
                                {{ totalConcated['61-75'] ? totalConcated['61-75'] : 0 }}
                            </div>
                            <div
                                class="participant-stats__minute-parent --bar --bar--win --bar--medium text-center flex-fill"
                                [ngClass]="lighter('totalConcated', '76-90')"
                            >
                                {{ totalConcated['76-90'] ? totalConcated['76-90'] : 0 }}
                                <div class="participant-stats__minute --left-minute">75`</div>
                                <div class="participant-stats__minute --right-minute">90`</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-12">
                    <div class="participant-stats__item-parent mb-0">
                        <div class="d-flex justify-content-between p-2 participant-stats__item">
                            <div class="font-weight-bold --bar-text --bar-text--win">{{ 'web.home' | translate }}</div>
                            <div class="font-weight-bold">&nbsp;</div>
                            <div class="font-weight-bold --bar-text --bar-text--draw">
                                {{ 'web.away' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- HOME -->
                <div class="col-12 col-md-6">
                    <div class="participant-stats__item-parent mb-0" *ngFor="let time of times; trackBy: trackByIndex">
                        <div class="participant-stats__item p-2">
                            <div class="d-flex">
                                <div class="no-square text-left">
                                    <span class="participant-stats__total-half--neutral w100 app-no-wrap"
                                        >{{ time[0] + '-' + time[1] }}`</span
                                    >
                                </div>

                                <div
                                    class="--bar --bar--win --bar--filled text-center align-self-stretch ml-2 flex-grow-1"
                                    [style.width.%]="40"
                                >
                                    <div
                                        class="--bar--filled --bar--filled-active"
                                        [style.width.%]="getWidth('homeConcated', time[0] + '-' + time[1])"
                                    >
                                        {{ getWidth('homeConcated', time[0] + '-' + time[1]) }}%
                                    </div>
                                </div>
                                <div class="no-square text-center font-weight-bold">
                                    {{
                                        homeConcated[time[0] + '-' + time[1]]
                                            ? homeConcated[time[0] + '-' + time[1]]
                                            : 0
                                    }}x
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- AWAY -->
                <div class="col-12 col-md-6">
                    <div class="participant-stats__item-parent mb-0" *ngFor="let time of times; trackBy: trackByIndex">
                        <div class="participant-stats__item p-2">
                            <div class="d-flex">
                                <div class="no-square text-left">
                                    <span class="participant-stats__total-half--neutral w100 app-no-wrap"
                                        >{{ time[0] + '-' + time[1] }}`</span
                                    >
                                </div>

                                <div
                                    class="--bar --bar--win --bar--filled text-center align-self-stretch ml-2 flex-grow-1"
                                    [style.width.%]="40"
                                >
                                    <div
                                        class="--bar--filled --bar--filled-active2"
                                        [style.width.%]="getWidth('awayConcated', time[0] + '-' + time[1])"
                                    >
                                        {{ getWidth('awayConcated', time[0] + '-' + time[1]) }}%
                                    </div>
                                </div>
                                <div class="no-square text-center font-weight-bold">
                                    {{
                                        awayConcated[time[0] + '-' + time[1]]
                                            ? awayConcated[time[0] + '-' + time[1]]
                                            : 0
                                    }}x
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</section>
