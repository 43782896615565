import { TennisBilanceStats } from '@interfaces/participant.interface';
import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@components/base.component';
import { reverse, sortBy } from 'lodash-es';


@Component({
    selector: 'app-tennis-bilance',
    templateUrl: './tennis-bilance.component.html',
})
export class TennisBilanceComponent extends BaseComponent implements OnInit {
    @Input()
    set bilance(value: TennisBilanceStats[]) {
        this.data = value.reverse().map((b:TennisBilanceStats):TennisBilanceStats => {
            let n;
            n = b.competitions_played !== null && b.competitions_won !== null
                ? b.competitions_played - b.competitions_won : 0;
            b.competitions_lost = Math.max(+n, 0) ?? null;

            n = b.matches_played !== null && b.matches_won !== null
                ? b.matches_played - b.matches_won : 0;
            b.matches_lost = Math.max(+n, 0) ?? null;

            n = b.synthetic_indoor_competitions_played !== null && b.synthetic_indoor_competitions_won !== null
                ? b.synthetic_indoor_competitions_played - b.synthetic_indoor_competitions_won : 0;
            b.synthetic_indoor_competitions_lost = Math.max(+n, 0) ?? null;

            n = b.synthetic_indoor_matches_played !== null && b.synthetic_indoor_matches_won !== null
                ? b.synthetic_indoor_matches_played - b.synthetic_indoor_matches_won : 0;
            b.synthetic_indoor_matches_lost = Math.max(+n, 0) ?? null;


            n = b.hard_court_competitions_played !== null && b.hard_court_competitions_won !== null
                ? b.hard_court_competitions_played - b.hard_court_competitions_won : 0;
            b.hard_court_competitions_lost = Math.max(+n, 0) ?? null;

            n = b.hard_court_matches_played !== null && b.hard_court_matches_won !== null
                ? b.hard_court_matches_played - b.hard_court_matches_won : 0;
            b.hard_court_matches_lost = Math.max(+n, 0) ?? null;


            n = b.unknown_competitions_played !== null && b.unknown_competitions_won !== null
                ? b.unknown_competitions_played - b.unknown_competitions_won : 0;
            b.unknown_competitions_lost = Math.max(+n, 0) ?? null;

            n = b.unknown_matches_played !== null && b.unknown_matches_won !== null
                ? b.unknown_matches_played - b.unknown_matches_won : 0;
            b.unknown_matches_lost = Math.max(+n, 0) ?? null;


            n = b.grass_competitions_played !== null && b.grass_competitions_won !== null
                ? b.grass_competitions_played - b.grass_competitions_won : 0;
            b.grass_competitions_lost = Math.max(+n, 0) ?? null;

            n = b.grass_matches_played !== null && b.grass_matches_won !== null
                ? b.grass_matches_played - b.grass_matches_won : 0;
            b.grass_matches_lost = Math.max(+n, 0) ?? null;


            n = b.red_clay_competitions_played !== null && b.red_clay_competitions_won !== null
                ? b.red_clay_competitions_played - b.red_clay_competitions_won : 0;
            b.red_clay_competitions_lost = Math.max(+n, 0) ?? null;

            n = b.red_clay_matches_played !== null && b.red_clay_matches_won !== null
                ? b.red_clay_matches_played - b.red_clay_matches_won : 0;
            b.red_clay_matches_lost = Math.max(+n, 0) ?? null;


            n = b.hardcourt_outdoor_competitions_played !== null && b.hardcourt_outdoor_competitions_won !== null
                ? b.hardcourt_outdoor_competitions_played - b.hardcourt_outdoor_competitions_won : 0;
            b.hardcourt_outdoor_competitions_lost = Math.max(+n, 0) ?? null;

            n = b.hardcourt_outdoor_matches_played !== null && b.hardcourt_outdoor_matches_won !== null
                ? b.hardcourt_outdoor_matches_played - b.hardcourt_outdoor_matches_won : 0;
            b.hardcourt_outdoor_matches_lost = Math.max(+n, 0) ?? null;


            n = b.hardcourt_indoor_competitions_played !== null && b.hardcourt_indoor_competitions_won !== null
                ? b.hardcourt_indoor_competitions_played - b.hardcourt_indoor_competitions_won : 0;
            b.hardcourt_indoor_competitions_lost = Math.max(+n, 0) ?? null;

            n = b.hardcourt_indoor_matches_played !== null && b.hardcourt_indoor_matches_won !== null
                ? b.hardcourt_indoor_matches_played - b.hardcourt_indoor_matches_won : 0;
            b.hardcourt_indoor_matches_lost = Math.max(+n, 0) ?? null;

            n = b.carpet_indoor_competitions_played && b.carpet_indoor_competitions_won
                ? b.carpet_indoor_competitions_played - b.carpet_indoor_competitions_won : 0;
            b.carpet_indoor_competitions_lost = Math.max(+n, 0) ?? null;

            n = b.carpet_indoor_matches_played && b.carpet_indoor_matches_won
                ? b.carpet_indoor_matches_played - b.carpet_indoor_matches_won : 0;
            b.carpet_indoor_matches_lost = Math.max(+n, 0) ?? null;

            return b;
        });
    }

    public activeRow = 0;

    public data: TennisBilanceStats[];

    public constructor() {
        super();
    }

    public ngOnInit(): void {
        this.data = reverse(sortBy(this.data, ['year']));
    }


    public trackByYear(index: number, val: any): number {
        return val.year;
    }
}
