

/**
 *  Participant stats totals
 *  @author Livescore <@score-stats.com>
 *  @copyright 2022 livescore
 */

import { SportConfigToken } from '@config/sport.config';
import { Component, Inject, OnInit } from '@angular/core';
import { ParticipantStats } from '@components/participant/statistics/participant-stats.abstract';
import { delay, tap } from 'rxjs/operators';


@Component({
    selector: 'app-participant-stats-totals',
    templateUrl: './participant-stats-total.component.html',
})
export class ParticipantStatsTotalsComponent extends ParticipantStats implements OnInit {
    [prop: string]: any;
    public svg1 = '';
    public svg2 = '';
    public svg3 = '';
    public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>) {
        super();
    }


    public ngOnInit(): void {
        this.dataLoaded.pipe(tap(() => {
            if (this.sportConfig.isHockey(this.sportName)) {
                this.periods = ['score_period_1', 'score_period_2', 'score_period_3', 'score_normal_time'];
            }
        }), delay(100)).subscribe(() => {
            if (this.period1) {
                this.graph('1', this.period1);
            }
            if (this.period2) {
                this.graph('2', this.period2);
            }
            if (this.period3 && this.sportConfig.isHockey(this.sportName)) {
                this.graph('3', this.period3);
            }
        });
    }


    public graph(key: string, form: Record<string, number>): void {
        const colorArr = ['--win-graph', '--draw-graph', '--lose-graph '];
        const pieData = [+form!.winPrct, +form!.drawPrct, +form!.losePrct];
        const total = pieData.reduce((p, c) => p + c, 0);
        const sectorAngleArr = [];
        let svg = '<svg viewBox="0 0 400 400" width="25px">';

        let startAngle = 0;
        let endAngle = 0;
        let x1 = 0;
        let x2 = 0;
        let y1 = 0;
        let y2 = 0;
        let percent = 0;


        // Get angles each slice swipes for sectorAngleArr
        for (let i = 0; i < pieData.length; i += 1) {
            const angle = Math.ceil(360 * pieData[i] / total);
            sectorAngleArr.push(angle);
        }

        for (let i = 0; i < sectorAngleArr.length; i += 1) {
            startAngle = endAngle;
            endAngle = startAngle + sectorAngleArr[i];

            // Check if the angle is over 180deg for large angle flag
            percent = endAngle - startAngle;
            let overHalf = 0;
            if (percent > 180) {
                overHalf = 1;
            }

            // Super fun math for calculating x and y positions
            x1 = 200 + 180 * Math.cos(Math.PI * startAngle / 180);
            y1 = 200 + 180 * Math.sin(Math.PI * startAngle / 180);

            x2 = 200 + 180 * Math.cos(Math.PI * endAngle / 180);
            y2 = 200 + 180 * Math.sin(Math.PI * endAngle / 180);

            const d = `M200,200  L${x1},${y1}  A180,180 0 ${overHalf},1 ${x2},${y2} z`;
            svg += `<path d="${d}" class="participant-stats__total-half${colorArr[i]}"  />`;
        }

        // Close SVG
        svg += '</svg>';

        this[`svg${key}`] = svg;
    }
}
