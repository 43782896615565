/* eslint-disable camelcase */
/**
 *  Participant stats abstract class
 *  @author Livescore <@score-stats.com>
 *  @copyright 2022 livescore
 */

import { PeriodKeys } from '@interfaces/match-data.interface';

import { Subject } from 'rxjs';

import SPORT_CONFIG from '@config/sport.config';

import { FormData, LastForm } from '@/modules/widgets/interfaces/widget.interface';


export class ParticipantStats {
    protected periods: PeriodKeys[] = ['score_period_1', 'score_period_2', 'score_normal_time'];
    protected uo: number[] = SPORT_CONFIG.statistics.underOver;
    protected form: FormData;
    public sportName: string;

    protected dataLoaded = new Subject<FormData>();

    public setForm(form: FormData): void {
        this.dataLoaded.next(form);
        this.form = form;
    }

    public setSport(name: string): void {
        this.sportName = name;
    }

    public reset(): void {

    }

    get formData(): FormData {
        return this.form;
    }


    get stats(): Record<string, any> | null {
        if (!this.form) {
            return null;
        }

        let data: Partial<Record<PeriodKeys, Record<string, number>>> = {};

        this.periods.forEach((key: PeriodKeys): void => {
            data = { ...data, [key]: this.periodStats(key, this.form.form.filter(f => !!f[key])) };
        });

        return data;
    }

    get normalTime(): Record<string, number> | null {
        return this.stats?.score_normal_time ?? null;
    }

    get period1(): Record<string, number> | null {
        return this.stats?.score_period_1 ?? null;
    }

    get period2(): Record<string, number> | null {
        return this.stats?.score_period_2 ?? null;
    }

    get period3(): Record<string, number> | null {
        return this.stats?.score_period_3 ?? null;
    }

    public trackByIndex(index: number): number {
        return index;
    }


    protected isHome(name: string): boolean {
        return this.form.name === name;
    }

    protected periodStats(period: PeriodKeys, form: LastForm[]): Record<string, number | string | Record<string, any>> {
        let win = 0;
        let lose = 0;
        let draw = 0;
        let total = 0;
        let totalHome = 0;
        let totalAway = 0;
        let goalsHome = 0;
        let goalsAway = 0;
        let goalsConcatedHome = 0;
        let goalsConcatedAway = 0;
        let bothTeamScore = 0;
        let noGoal = 0;
        let uo: Record<string, any> = {};
        let sumGoals: number = 0;

        form.forEach((f: LastForm) => {
            if (period in f) {
                // @ts-ignore
                const [home, away] = f[period].split(':');
                const isHome = this.isHome(f.home_team);

                if ((isHome && home! > away!) || (!isHome && home! < away!)) {
                    win += 1;
                } else if ((isHome && home! < away!) || (!isHome && home! > away!)) {
                    lose += 1;
                } else {
                    draw += 1;
                }

                if (isHome) {
                    goalsHome += +home!;
                    goalsConcatedHome += +away!;
                    totalHome += 1;
                } else {
                    goalsAway += +away!;
                    goalsConcatedAway += +home!;
                    totalAway += 1;
                }

                if (+home! > 0 && +away! > 0) {
                    bothTeamScore += 1;
                }

                if (+home! === 0 && +away! === 0) {
                    noGoal += 1;
                }


                // Calculate Under/Over
                if (period === 'score_normal_time') {
                    const sum = +home! + +away!;
                    sumGoals += sum;
                    this.uo.forEach((key: number): void => {
                        if (!(key in uo)) {
                            uo = { ...uo, [key]: { under: 0, over: 0, underPrct: 0, overPrct: 0 } };
                        }
                        if (key > sum) {
                            uo[key].under += 1;
                            return;
                        }

                        uo[key].over += 1;
                    });
                }

                total += 1;
            }
        });

        if (period === 'score_normal_time') {
            this.uo.forEach((key: number): void => {
                if (key in uo) {
                    uo[key].underPrct = Math.round(uo[key].under / total * 100);
                    uo[key].overPrct = Math.round(uo[key].over / total * 100);
                }
            });
        }

        const winPrct = Math.round(win / total * 100);
        const losePrct = Math.round(lose / total * 100);
        const drawPrct = 100 - (winPrct + losePrct);
        const bothTeamScorePrct = Math.round(bothTeamScore / total * 100);
        const noGoalPrct = Math.round(noGoal / total * 100);
        const sumGoalsAverage = sumGoals / total;

        const goalsTotal = goalsHome + goalsAway;
        const goalsHomePrct = Math.round(goalsHome / goalsTotal * 100);
        const goalsAwayPrct = 100 - goalsHomePrct;
        const goalsAverage = (goalsTotal / total).toFixed(1);
        const goalsAverageHome = (goalsHome / totalHome).toFixed(1);
        const goalsAverageAway = (goalsAway / totalAway).toFixed(1);

        const sumAverages = +goalsAverageHome + +goalsAverageAway;
        const goalsAverageHomePrct = Math.round(+goalsAverageHome / sumAverages * 100);
        const goalsAverageAwayPrct = 100 - goalsAverageHomePrct;

        const goalsConcatedTotal = goalsConcatedHome + goalsConcatedAway;
        const goalsConcatedHomePrct = Math.round(goalsConcatedHome / goalsConcatedTotal * 100);
        const goalsConcatedAwayPrct = 100 - goalsConcatedHomePrct;
        const goalsConcatedAverage = (goalsConcatedTotal / total).toFixed(1);
        const goalsConcatedAverageHome = (goalsConcatedHome / totalHome).toFixed(1);
        const goalsConcatedAverageAway = (goalsConcatedAway / totalAway).toFixed(1);

        const sumConcatedAverages = +goalsConcatedAverageHome + +goalsConcatedAverageAway;
        const goalsConcatedAverageHomePrct = Math.round(+goalsConcatedAverageHome / sumConcatedAverages * 100);
        const goalsConcatedAverageAwayPrct = 100 - goalsConcatedAverageHomePrct;

        return { win,
            lose,
            draw,
            winPrct,
            losePrct,
            drawPrct,
            total,
            goalsTotal,
            goalsHomePrct,
            goalsAwayPrct,
            goalsHome,
            goalsAway,
            goalsAverage,
            goalsAverageHome,
            goalsAverageAway,
            goalsAverageHomePrct,
            goalsAverageAwayPrct,
            goalsConcatedTotal,
            goalsConcatedHome,
            goalsConcatedAway,
            goalsConcatedHomePrct,
            goalsConcatedAwayPrct,
            goalsConcatedAverage,
            goalsConcatedAverageHome,
            goalsConcatedAverageAway,
            goalsConcatedAverageHomePrct,
            goalsConcatedAverageAwayPrct,
            bothTeamScore,
            bothTeamScorePrct,
            noGoal,
            noGoalPrct,
            sumGoalsAverage,
            uo,
        };
    }
}
