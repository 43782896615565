/* eslint-disable @angular-eslint/no-input-rename */
import { Component, Input, ChangeDetectionStrategy, HostListener, NgZone } from '@angular/core';
import { sortBy, uniqBy, groupBy } from 'lodash-es';

import { Player, PlayerStatistics, PlayerStatsInfo, StatsTournament } from '@interfaces/match-data.interface';

import APP_CONFIG from '@config/app.config';
import SPORT_CONFIG from '@config/sport.config';

import $ from '@shared/jquery';
import { BaseComponent } from '@components/base.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'app-players-v2',
    templateUrl: './players-v2.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayersV2Component extends BaseComponent {
    @Input()
    public home: string;

    @Input()
    public away: string;

    @Input('formation_home')
    public formationHome: string;

    @Input('formation_away')
    public formationAway: string;

    @Input()
    public statistics: PlayerStatistics | null = null;

    @Input()
    public sportName: string;

    @Input()
    public sportId: number;

    @Input()
    public seasonId: number;

    public subHome: Player[];
    public subAway: Player[];
    public players: { [prop: string]: Player[] };
    public positionCss: Record<string, string> = {};
    private dataVal: Player[];
    private playerSelectedVal: number | null = null;

    public constructor(

        private deviceService: DeviceDetectorService,
        private ngZone: NgZone,

    ) {
        super();
    }

    get data(): any {
        return this.dataVal;
    }

    @Input()
    set data(v: any) {
        v = v.map((val: Player) => {
            val.sort = 100;
            if (val.position === 'goalkeeper' || val.position?.toLowerCase() === 'g' ||
            val.position?.toLowerCase() === 'goalie' ||
                val.position?.toLowerCase() === 'pr') {
                val.sort = 1;
            } else if (val.position === 'defender' || val.position?.toLowerCase() === 'd' ||
             val.position?.toLowerCase() === 'right back' || val.position?.toLowerCase() === 'left back' ||
                val.position?.toLowerCase() === 'ho' || val.position?.toLowerCase() === 'g-f') {
                val.sort = 2;
            } else if (val.position === 'midfielder' || val.position?.toLowerCase() === 'm' ||
             val.position?.toLowerCase() === 'left wink' ||
                val.position?.toLowerCase() === 'l' || val.position?.toLowerCase() === 'c-f' ||
                val.position?.toLowerCase() === 'hb') {
                val.sort = 3;
            } else if (val.position === 'forward' || val.position?.toLowerCase() === 'f' ||
            val.position?.toLowerCase() === 'right wink' ||
                val.position?.toLowerCase() === 'br' || val.position?.toLowerCase() === 'uf') {
                val.sort = 4;
            } else if (val.position?.toLowerCase() === 'sh' || val.position?.toLowerCase() === 'center' ||
            val.position?.toLowerCase() === 'fb') {
                val.sort = 5;
            } else if (val.position?.toLowerCase() === 'fh') {
                val.sort = 6;
            } else if (val.position?.toLowerCase() === 'c') {
                val.sort = 7;
            } else if (val.position?.toLowerCase() === 'w') {
                val.sort = 8;
            } else if (val.position?.toLowerCase() === 'fl') {
                val.sort = 9;
            } else if (val.position?.toLowerCase() === 'ub') {
                val.sort = 9;
            }

            return val;
        }).filter((val: Player) => !val.role?.includes('-duplicity'));
        this.dataVal = sortBy(v, ['sort', 'position']);
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    public _isHome: boolean = true;

    /**
     * Is hpme tab active
     * @return {boolean}
     */
    public get isHome(): boolean {
        return this._isHome;
    }

    get hasCards(): boolean {
        return SPORT_CONFIG.playerStats.redYellowCard.includes(this.sportName);
    }

    get playerTournament(): {name: string;id: number}[] {
        let tournaments: {name: string;id: number}[] = [];
        this.data.forEach((player: Player) => {
            tournaments = [...tournaments, ...player.statsTournament!.map(
                (val: StatsTournament) => ({ id: val.tournament_id,
                    name: val.tournament_name,
                    order: val.tournamentOrder })),
            ];
        });

        return sortBy(uniqBy(tournaments, 'id'), 'order');
    }

    get colspan(): number {
        let x = 5;
        if (this.showMatchPlayed) {
            x += 1;
        }
        if (this.showStats) {
            x += 1;
        }
        if (this.hasAssists) {
            x += 1;
        }
        if (this.hasCards) {
            x += 2;
        }

        return x;
    }

    get playerSelectedT(): number | undefined {
        if (this.playerSelectedVal === null) {
            return this.playerTournament[0]?.id;
        }
        return this.playerSelectedVal;
    }

    get finalData(): Record<string, Player[]> {
        return groupBy(this.data, 'position') as Record<string, Player[]>;
    }

    get showMatchPlayed(): boolean {
        return this.isSoccer(this.sportName);
    }

    get showStats(): boolean {
        return this.isHockey(this.sportName) || this.isSoccer(this.sportName) || this.isHandball(this.sportName);
    }

    get hasAssists(): boolean {
        return this.isHockey(this.sportName) || this.isSoccer(this.sportName) || this.isHandball(this.sportName);
    }


    public over(p: number, side: string = 'home'): void {
        $('.app-players-field').addClass('d-none');
        $(`#p_${side}_${p}`).removeClass('d-none');
    }

    public out(): void {
        $('.app-players-field').addClass('d-none');
    }

    public trackByFn(index: number): number {
        return index;
    }

    public activePlayerT(id: number): void {
        this.playerSelectedVal = id;
    }

    public playerData(stats: StatsTournament[], type: PlayerStatsInfo): number | string {
        const tournament = stats.find((val: StatsTournament) => val.tournament_id === this.playerSelectedT);

        if (this.playerSelectedT === 0) {
            let total = 0;
            stats.forEach((val: StatsTournament) => { total += +val[type]!; });

            return total || '-';
        }

        if (tournament?.[type] === '0' || tournament?.[type] === 0) {
            return '-';
        }
        return tournament?.[type] ?? '-';
    }

    @HostListener('window:scroll', ['$event'])
    private onScroll(): void {
        this.ngZone.runOutsideAngular((): void => {
            const el = document.getElementById('players-squad');
            if (!el) {
                return;
            }
            const el2 = document.getElementById('players-squad-virtual');
            const rect = el!.getBoundingClientRect();

            if (rect.y < 0) {
                let num = Math.abs(rect.y);
                if (this.deviceService.isMobile()) {
                    num += 60;
                }

                el2!.style.top = `${num}px`;
            } else {
                el2!.style.top = '0px';
            }
        });
    }

    private mapPlayers(val: Player): Player {
        if (val.flag === null) {
            val.flag = APP_CONFIG.defaultAvatar;
        }
        return val;
    }
}
