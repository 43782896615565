/**
 *  Service for participants
 *  @author Livescore <score-stats.com>
 *  @copyright 2019 livescore
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, distinctUntilChanged, map, tap } from 'rxjs/operators';

import { ParticipantCompareInterface, ParticipantInterface } from '@interfaces/participant.interface';


import { CommonService } from '../shared/common.service';


import URL_CONFIG from '../config/url.config';

import { BaseService } from './base.service';
import { LangService } from './lang.service';
import { UtilsService } from './utils.service';

import { FormData } from '@/modules/widgets/interfaces/widget.interface';

@Injectable({
    providedIn: 'root',
})
export class ParticipantService extends BaseService {
    public constructor(
        private http: HttpClient,
        private common: CommonService,
        private lang: LangService,
    ) {
        super();
    }

    public getForm(participantId: any, params: Record<string, any> = {}): Observable<FormData> {
        const url = UtilsService.replace(
            'pid',
            participantId as string,
            URL_CONFIG.api.participantForm,
        );
        const options = { params };

        return this.http.get<FormData>(url, options).pipe(
            distinctUntilChanged(),
            catchError(this.common.errorCallback2()), // then handle the error
            tap(this.common.networkOnline()),
        );
    }


    public getParticipantStandings(seasonId: number | undefined, subtournamentId: number,
        participantId: number, codeName: string): Observable<Record<string, any>[]> {
        const url = `${URL_CONFIG.api.participantStandings(seasonId, subtournamentId, participantId)}?lang=${codeName}`;

        const options = {};
        return this.http.get<Record<string, any>[]>(url, options).pipe(
            distinctUntilChanged(),
            catchError(this.common.errorCallback), // then handle the error
        );
    }

    public getCompare(firstPId: any, secondPId: any): Observable<ParticipantCompareInterface> {
        if (!firstPId || !secondPId) {
            throw new Error(`Wrong participant ID ${firstPId} and ${secondPId}`);
        }

        let url = UtilsService.replace(
            'first',
            firstPId as string,
            URL_CONFIG.api.getParticipantCompare,
        );
        url = UtilsService.replace(
            'second',
            secondPId as string,
            url,
        );

        const params = new HttpParams()
            .set('lang', this.lang.getLangSnapshot() as string);


        return this.http.get<ParticipantCompareInterface>(url, { params }).pipe(
            catchError(this.common.errorCallback2()), // then handle the error
            tap(this.common.networkOnline()),
        );
    }

    /**
     * Get participant detail
     * @param {number} participantId
     * @return {Promise<Observable<ParticipantInterface>>}
     */
    public async get(
        participantId: any,
        limit: any = 100,
    ): Promise<Observable<ParticipantInterface> | Error> {
        const url = UtilsService.replace(
            'participantId',
            participantId as string,
            URL_CONFIG.api.getParticipant,
        );
        return this.lang.getLang().then(
            (iso): Observable<ParticipantInterface> => {
                const params = new HttpParams()
                    .set('lang', iso)
                    .set('limit', limit)
                    .set('id', participantId);
                const options = params ? { params } : {};
                return this.http.get<ParticipantInterface>(url, options).pipe(
                    catchError(this.common.errorCallback2()), // then handle the error
                    tap(this.common.networkOnline()),
                    map((p: ParticipantInterface) => {
                        if (p.players) {
                            p.players = p.players?.filter(value => value.name);
                        }

                        return p;
                    }),
                );
            },
            (er): Promise<Error> => Promise.reject(new Error(er)),
        );
    }
}
