import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Form, Status } from '@interfaces/participant.interface';
import * as _ from 'underscore';

@Component({
    selector: 'app-participant-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class ParticipantFormComponent {
    @Input()
    public form: Form[];

    @Output()
    onLoad: EventEmitter<number> = new EventEmitter();

    public prct: number;

    public trans: string[] = [];


    public colors = [
        {
            backgroundColor: ['rgb(55, 212, 57)', '#f6b20c', '#df0303'],
        },
    ];

    public total: number = 0;


    public data: {fill: number, color: string}[] = [{
        fill: 15,
        color: '#80e080',
    }, {
        fill: 55,
        color: '#f6b20c',
    }, {
        fill: 30,
        color: '#df0303',
    }];

    public constructor(private translate: TranslateService) {
        this.translate.get('web.participant_form_win').subscribe((): void => {
            this.trans.push(
                ...[
                    this.translate.instant('web.participant_form_win'),
                    this.translate.instant('web.participant_form_tie'),
                    this.translate.instant('web.participant_form_lose'),
                ],
            );
        });
    }


    public ngOnInit(): void {
        const lose = _.reduce(
            this.form,
            (sum, val: Form): number => {
                if (val.status === Status.LOSE || val.status === Status.OTLOSE) {
                    return sum + 1;
                }
                return sum;
            },
            0,
        );
        const win = _.reduce(
            this.form,
            (sum, val: Form): number => {
                if (val.status === Status.WIN || val.status === Status.OTWIN) {
                    return sum + 1;
                }
                return sum;
            },
            0,
        );
        const draw = _.reduce(
            this.form,
            (sum, val: Form): number => {
                if (val.status === Status.DRAW) {
                    return sum + 1;
                }
                return sum;
            },
            0,
        );


        this.total = Math.min(lose + win + draw, this.form?.length ?? 0);

        const winPrct: number = 100;
        const tiePrct: number = 100;

        const winResult = Math.floor((win / this.total) * winPrct);
        const tieResult = Math.floor(((draw / this.total) * tiePrct) / 2);
        const tieResultSum = Math.floor(((draw / this.total) * tiePrct));
        const loseResult = 100 - (winResult + tieResultSum);


        this.data[0]!.fill = winResult;
        this.data[1]!.fill = tieResultSum;
        this.data[2]!.fill = loseResult;
        this.setPLugin(winResult + tieResult);
        this.generateGraph();
    }

    public get totalMatches(): number {
        return this.total;
    }

    private setPLugin(val: number): void {
        this.onLoad.emit(val);
        this.prct = val;
    }

    private generateGraph(): void {
        const doughnut = document.querySelector('#doughnut');
        const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        let filled = 0;
        svg.setAttribute('width', '100%');
        svg.setAttribute('height', '120%');
        svg.setAttribute('viewBox', '0 0 100 100');
        svg.classList.add('doughnut-svg');


        doughnut!.appendChild(svg);


        this.data.forEach((o: Record<string, any>) => {
            const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
            const startAngle = -90;
            const radius = 30;
            const cx = 50;
            const cy = 50;
            const animationDuration = 2000;
            const strokeWidth = 15;
            const dashArray = 2 * Math.PI * radius;
            const dashOffset = dashArray - (dashArray * o.fill / 100);
            const angle = (filled * 360 / 100) + startAngle;
            const currentDuration = animationDuration * o.fill / 100;
            const delay = animationDuration * filled / 100;
            circle.setAttribute('r', `${radius}`);
            circle.setAttribute('cx', `${cx}`);
            circle.setAttribute('cy', `${cy}`);
            circle.setAttribute('fill', 'transparent');
            circle.setAttribute('stroke', o.color);
            circle.setAttribute('stroke-width', `${strokeWidth}`);
            circle.setAttribute('stroke-dasharray', `${dashArray}`);
            circle.setAttribute('stroke-dashoffset', `${dashArray}`);
            circle.style.transition = `stroke-dashoffset ${currentDuration}ms linear ${delay}ms`;
            circle.setAttribute('transform', `rotate(${angle} ${cx} ${cy})`);
            svg.appendChild(circle);
            filled += o.fill;
            setTimeout(() => {
                const key = 'stroke-dashoffset';
                (circle.style as any)[key] = dashOffset;
            }, 100);
        });
    }
}
