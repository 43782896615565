/**
 *  Participant stats parent component
 *  @author Livescore <@score-stats.com>
 *  @copyright 2022 livescore
 */

import { MatchType } from '@interfaces/league-table.interface';
import { Stats } from '@interfaces/participant.interface';

import { ParticipantService } from '@services/participant.service';
import { Component, AfterContentInit, Input, ContentChildren, QueryList } from '@angular/core';
import { BaseComponent } from '@components/base.component';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { LastForm, FormData } from '@/modules/widgets/interfaces/widget.interface';

@Component({
    selector: 'app-participant-stats',
    templateUrl: './participant-stats.component.html',
})
export class ParticipantStatsComponent extends BaseComponent implements AfterContentInit {
    @ContentChildren('playerStats') viewChildren!: QueryList<Stats>;

    @Input()
    pid: number;

    @Input()
    sportName: string;

    public lastMatches: number = 10;


    public $call: Observable<FormData>;

    public constructor(public service:ParticipantService) {
        super();
    }

    public onLastMatchesChange(num: number): void {
        this.lastMatches = num;
        this.call();
    }

    public ngAfterContentInit(): void {
        this.call();
    }


    private call(): void {
        this.reset();
        this.$call = this.service.getForm(this.pid, { limit: this.lastMatches, extraStats: 1 })
            .pipe(map((v: FormData) => {
                v.form = v.form.filter((f: LastForm) => f.type !== MatchType.UPCOMING);
                return v;
            }), tap((v: FormData) => {
                this.finished();
                this.viewChildren.forEach((ch: Stats): void => {
                    ch.reset();
                    ch.setSport(this.sportName);
                    ch.setForm(v);
                });
            }));
    }
}
