/**
 *  Animation score change
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2021 livescore
 */
import { style, animate, transition, keyframes, group } from '@angular/animations';

export const SCORE_BACK_ANIMATION = [
    transition(':increment', [
        group([
            animate(
                '8s 0.3s',
                keyframes([
                    style({ color: 'white', backgroundColor: '#d45555' }),
                    style({ color: 'white', backgroundColor: '#f2b068' }),
                    style({ color: 'white', backgroundColor: '#d45555' }),
                    style({ color: 'white', backgroundColor: '#f2b068' }),
                    style({ color: 'white', backgroundColor: '#d45555' }),
                    style({ color: 'white', backgroundColor: '#f2b068' }),
                    style({ color: 'white', backgroundColor: '#d45555' }),
                    style({ color: 'white', backgroundColor: '#f2b068' }),
                    style({ color: 'white', backgroundColor: '#d45555' }),
                    style({ color: 'white', backgroundColor: '#f2b068' }),
                ]),
            ),
        ]),
    ]),
];
