

/**
 *  Participant stats timeline
 *  @author Livescore <@score-stats.com>
 *  @copyright 2022 livescore
 */

import { SportConfigToken } from '@config/sport.config';
import { Component, Inject, OnInit } from '@angular/core';
import { ParticipantStats } from '@components/participant/statistics/participant-stats.abstract';
import { delay, tap } from 'rxjs/operators';

import { LastForm } from '@widgets/interfaces/widget.interface';


@Component({
    selector: 'app-participant-stats-timeline',
    templateUrl: './participant-stats-timeline.component.html',
})
export class ParticipantStatsTimelineComponent extends ParticipantStats implements OnInit {
    public total: number = 0;
    public totalHome: number = 0;
    public totalAway: number = 0;


    private validTypes: string[] = ['yellow_card', 'yellow_red_card',
        'red_card', 'corner_kick', 'free_kick', 'throw_in', 'offside', 'suspension'];


    public data: {name: string, total: number, homeTotal: number,
        awayTotal: number}[] = [];

    public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>) {
        super();
    }

    public reset(): void {
        this.total = 0;
        this.totalHome = 0;
        this.totalAway = 0;
        this.data = [];
    }

    public ngOnInit(): void {
        this.dataLoaded.pipe(tap(() => {
            if (this.sportConfig.isHockey(this.sportName)) {
                this.periods = ['score_period_1', 'score_period_2', 'score_period_3', 'score_normal_time'];
            }
        }), delay(100)).subscribe(() => {
            this.form.form.forEach(((f: LastForm) => {
                const isHome = this.isHome(f.home_team);


                if (!f.timeline || f.timeline.length === 0) { return; }

                this.total += 1;

                if (isHome) {
                    this.totalHome += 1;
                } else {
                    this.totalAway += 1;
                }

                f.timeline.forEach((val) => {
                    if (this.validTypes.includes(val.type)) {
                        let fVal = this.data.find(v => v.name === val.type);

                        if (!fVal) {
                            fVal = {
                                name: val.type,
                                total: 0,
                                homeTotal: 0,
                                awayTotal: 0,
                            };
                            this.data.push(fVal);
                        }

                        if (val.competitor) {
                            if (val.competitor === 'away' && !isHome) {
                                fVal.awayTotal += 1;
                                fVal.total += 1;
                            } else if (val.competitor === 'home' && isHome) {
                                fVal.homeTotal += 1;
                                fVal.total += 1;
                            }
                        }
                    }
                });
            }));
        });
    }

    get normalizeData(): {name: string, total: number, homeTotal: number,
        awayTotal: number}[] {
        return this.data.filter(v => v.total !== 0);
    }
}
