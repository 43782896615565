<div class="table-sport__row app-cursor" data-cy="match-item" [class.active]="isActive" *ngIf="match !== null">
    <div
        *ngIf="possibleGoal"
        class="app-possible-goal"
        title="{{ 'web.possible_chance' | translate }}"
        aria-label="{{ 'web.possible_chance' | translate | transinit }}"
    >
        <div class="app-possible-goal__pulse"></div>
    </div>
    <div
        (click)="active()"
        class="table-sport__cell table-sport__cell--start d-none d-md-flex app-cursor"
        *ngIf="!widgetView"
    >
        {{ match.start_date | livDate : dateFormat }}
    </div>
    <div (click)="active()" class="table-sport__cell table-sport__cell--time app-cursor" *ngIf="oneline || !isMobile">
        <div
            [@state]="match.code_state"
            [@.disabled]="animationsDisabled"
            [class.app-state-color-basic]="match.match_state != 'inplay-live' && match.match_state != 'inplay-pause'"
            class="d-none d-sm-block"
        >
            {{ 'status.' + sportName + '.' + match.code_state | translate }}
            <div>
                <app-match-time
                    *ngIf="match.match_state == 'inplay-live' && match.display_time"
                    [time]="match.minute"
                    [addTime]="match.added_time"
                    [code_state]="match.code_state"
                    [lastTimeUpdate]="match?.last_time_update"
                    [sportName]="sportName"
                    [periodLength]="match.period_length"
                    [stoppageTimeAnnounced]="match!.stoppage_time_announced"
                ></app-match-time>
            </div>
        </div>
        <div
            [@state]="match.code_state"
            [@.disabled]="animationsDisabled"
            [class.app-state-color-basic]="match.match_state != 'inplay-live' && match.match_state != 'inplay-pause'"
            [class.app-mob-status-wrap]="match.match_state !== 'before'"
            class="d-sm-none app-mob-status"
        >
            <ng-container *ngIf="match.match_state !== 'before'"
                >{{ 'status.' + sportName + '.' + match.code_state + '_short' | translate }} <br
            /></ng-container>
            <ng-container
                *ngIf="
                    match.match_state != 'inplay-live' &&
                    match.match_state != 'inplay-pause' &&
                    match.match_state !== 'before'
                "
                >{{ match.start_date | livDate : dateFormat2 }}</ng-container
            >
            <ng-container *ngIf="match.match_state === 'before'">
                <span class="app-mob-status--small"> {{ match.start_date | livDate : dateFormat }}</span>
            </ng-container>
            <app-match-time
                *ngIf="match.match_state == 'inplay-live' && match.display_time"
                [time]="match.minute"
                [addTime]="match.added_time"
                [code_state]="match.code_state"
                [lastTimeUpdate]="match?.last_time_update"
                [sportName]="sportName"
                [periodLength]="match.period_length"
                [stoppageTimeAnnounced]="match!.stoppage_time_announced"
            ></app-match-time>
        </div>
    </div>
    <div class="table-sport__cell table-sport__cell--phase d-none d-md-flex app-cursor" (click)="active()">
        <ng-container *ngIf="match.round_name != null">{{ match.round_name }}</ng-container>
        <ng-container *ngIf="match.round_name == null && match.round != null"
            >{{ match.round }}. {{ 'web.round' | translate | transinit }}</ng-container
        >
    </div>

    <ng-container *ngIf="oneline">
        <div class="table-sport__cell table-sport__cell--team justify-content-end app-cursor" (click)="active()">
            <span
                *ngIf="match.participants[0].table_position !== null && page == 'match-list'"
                class="match-list-table-position small font-weight-normal text-muted mr-1"
                >({{ match.participants[0].table_position }})</span
            >
            <span
                *ngIf="match.participants[0]?.seed !== null && page == 'match-list'"
                class="match-list-table-position small font-weight-normal text-muted ml-1"
                title="{{ 'web.seed_position' | translate }}"
                >({{ match.participants[0]?.seed }})</span
            >
            <span [class.font-weight-bold-extend]="isWinnerHome()">{{ match.participants[0].name }}</span>
            <span
                *ngIf="match.participants[0].tennis_tournament_info !== null"
                class="small font-weight-normal text-muted d-none d-lg-inline ml-1"
                >({{ match.participants[0].tennis_tournament_info.short_name }})</span
            >
            <i class="fas fa-trophy ml-1" *ngIf="match.participants[0].cuptree_is_final"></i>
            <span
                class="game-card game-card--red ml-1"
                matTooltip="{{ 'web.red_cards_tooltip' | translate }}"
                matTooltipPosition="after"
                *ngIf="match.participants[0].red_cards > 0"
            >
                {{ match.participants[0].red_cards }}
            </span>
            <span
                matTooltip="{{ 'web.scratch_tooltip' | translate }}"
                matTooltipPosition="after"
                *ngIf="match.participants[0].scratch"
            >
                <span class="fa-stack">
                    <i class="far fa-circle fa-stack-2x"></i>
                    <i class="fas fa-plus fa-circle fa-stack-1x liv-scratch"></i>
                </span>
            </span>
        </div>
        <div
            *ngIf="!deviceService.isMobile()"
            class="table-sport__cell table-sport__cell--img app-cursor"
            (click)="active()"
        >
            <img
                (error)="imageError($event)"
                src="{{ cdn + imageHome | responsive : 'participant' : 'small' }}"
                alt="{{ match.participants[0].name }}"
                loading="lazy"
                width="25"
                height="25"
                class="img-fluid img-fluid--wauto"
            />
        </div>

        <div
            class="table-sport__cell table-sport__cell--score app-cursor"
            [class.app-score-color-basic]="match.match_state != 'inplay-live' && match.match_state != 'inplay-pause'"
            (click)="active()"
        >
            <app-score
                *ngIf="scoreActive"
                [sport]="sportName"
                [data]="match.score"
                [match_state]="match.match_state"
                [codeState]="match.code_state"
                [oneline]="oneline"
            ></app-score>
        </div>
        <div
            *ngIf="!deviceService.isMobile()"
            class="table-sport__cell table-sport__cell--img app-cursor"
            (click)="active()"
        >
            <img
                (error)="imageError($event)"
                src="{{ cdn + imageAway | responsive : 'participant' : 'small' }}"
                loading="lazy"
                width="25"
                height="25"
                alt="{{ match.participants[1]?.name }}"
                class="img-fluid img-fluid--wauto"
            />
        </div>
        <div class="table-sport__cell table-sport__cell--team app-cursor" (click)="active()">
            <i class="fas fa-trophy mr-1" *ngIf="match.participants[1].cuptree_is_final"></i>
            <span
                class="game-card game-card--red mr-1"
                matTooltip="{{ 'web.red_cards_tooltip' | translate }}"
                matTooltipPosition="after"
                *ngIf="match.participants[1]?.red_cards > 0"
            >
                {{ match.participants[1]?.red_cards }}
            </span>
            <span
                matTooltip="{{ 'web.scratch_tooltip' | translate }}"
                matTooltipPosition="after"
                *ngIf="match.participants[1]?.scratch"
            >
                <span class="fa-stack">
                    <i class="far fa-circle fa-stack-2x"></i>
                    <i class="fas fa-plus fa-circle fa-stack-1x liv-scratch"></i>
                </span>
            </span>
            <span [class.font-weight-bold-extend]="isWinnerAway()">{{ match.participants[1].name }}</span>
            <span
                *ngIf="match.participants[1]?.table_position !== null && page == 'match-list'"
                class="match-list-table-position small font-weight-normal text-muted ml-1"
                >({{ match.participants[1]?.table_position }})</span
            >
            <span
                *ngIf="match.participants[1]?.seed !== null && page == 'match-list'"
                class="match-list-table-position small font-weight-normal text-muted ml-1"
                title="{{ 'web.seed_position' | translate }}"
                >({{ match.participants[1]?.seed }})</span
            >
            <span
                *ngIf="match.participants[1]?.tennis_tournament_info !== null"
                class="small font-weight-normal text-muted d-none d-lg-inline ml-1"
                >({{ match.participants[1]?.tennis_tournament_info?.short_name }})</span
            >
        </div>

        <div
            class="table-sport__cell table-sport__cell--periods app-score d-none d-xl-inline app-cursor"
            *ngIf="!widgetView"
            (click)="active()"
        >
            <app-match-action-icons
                [matchActions]="matchActions"
                [sportName]="sportName"
                [playerStatistics]="playerStatistics"
                [substitutions]="substitutions"
                class="float-left pr-3 app-match-action-icons app-match-action-icons-{{ sportName }}"
            ></app-match-action-icons>
            <app-score
                *ngIf="scoreActive"
                [sport]="sportName"
                [data]="match.score"
                [match_state]="match.match_state"
                [codeState]="match.code_state"
                [oneline]="oneline"
                [onlyPeriods]="true"
            ></app-score>
        </div>
    </ng-container>

    <ng-container *ngIf="!oneline">
        <div class="table-sport__cell table-sport__cell--teams app-cursor" (click)="active()">
            <div class="tennis-set-match" *ngIf="setChange" [ngClass]="setClass">
                {{ 'web.' + setText | translate }}
            </div>
            <div class="table-sport__cell--teams-child">
                <img
                    (error)="imageError($event)"
                    *ngIf="imageHome"
                    src="{{ cdn + imageHome | responsive : 'participant' : 'small' }}"
                    width="16"
                    height="16"
                    loading="lazy"
                    class="mr-1 img-fluid--wauto"
                    alt="{{ match.participants[0].name }}"
                />
                <span [class.font-weight-bold]="isWinnerHome()">{{ match.participants[0].name }}</span>
                <span
                    *ngIf="match.participants[0].table_position !== null && page == 'match-list'"
                    class="match-list-table-position small font-weight-normal text-muted ml-1"
                    >({{ match.participants[0].table_position }})</span
                >
                <span
                    *ngIf="match.participants[0]?.seed !== null && page == 'match-list'"
                    class="match-list-table-position small font-weight-normal text-muted ml-1"
                    title="{{ 'web.seed_position' | translate }}"
                    >({{ match.participants[0]?.seed }})</span
                >
                <span
                    *ngIf="match.participants[0].tennis_tournament_info !== null"
                    class="small font-weight-normal text-muted d-none d-lg-inline ml-1"
                    >({{ match.participants[0].tennis_tournament_info.short_name }})</span
                >
                <i class="fas fa-trophy ml-1" *ngIf="match.participants[0].cuptree_is_final"></i>
                <span
                    class="game-card game-card--red ml-1"
                    matTooltip="{{ 'web.red_cards_tooltip' | translate }}"
                    matTooltipPosition="after"
                    *ngIf="match.participants[0].red_cards > 0"
                >
                    {{ match.participants[0].red_cards }}
                </span>
                <span
                    matTooltip="{{ 'web.scratch_tooltip' | translate }}"
                    matTooltipPosition="after"
                    *ngIf="match.participants[0].scratch"
                >
                    <span class="fa-stack">
                        <i class="far fa-circle fa-stack-2x"></i>
                        <i class="fas fa-plus fa-circle fa-stack-1x liv-scratch"></i>
                    </span>
                </span>
                <span *ngIf="isHomeBatting">
                    <svg class="icon">
                        <use href="#icon-cricket-bat" />
                    </svg>
                </span>
            </div>
            <div class="table-sport__cell--teams-child">
                <img
                    (error)="imageError($event)"
                    *ngIf="imageAway"
                    src="{{ cdn + imageAway | responsive : 'participant' : 'small' }}"
                    width="16"
                    height="16"
                    loading="lazy"
                    class="mr-1 img-fluid--wauto"
                    alt="{{ match.participants[1].name }}"
                />
                <span [class.font-weight-bold]="isWinnerAway()">{{ match.participants[1].name }}</span>
                <span
                    *ngIf="match.participants[1].table_position !== null && page == 'match-list'"
                    class="match-list-table-position small font-weight-normal text-muted ml-1"
                    >({{ match.participants[1].table_position }})</span
                >
                <span
                    *ngIf="match.participants[1]?.seed !== null && page == 'match-list'"
                    class="match-list-table-position small font-weight-normal text-muted ml-1"
                    title="{{ 'web.seed_position' | translate }}"
                    >({{ match.participants[1]?.seed }})</span
                >
                <span
                    *ngIf="match.participants[1].tennis_tournament_info !== null"
                    class="small font-weight-normal text-muted d-none d-lg-inline ml-1"
                    >({{ match.participants[1].tennis_tournament_info?.short_name }})</span
                >
                <i class="fas fa-trophy ml-1" *ngIf="match.participants[1].cuptree_is_final"></i>
                <span
                    class="game-card game-card--red ml-1"
                    matTooltip="{{ 'web.red_cards_tooltip' | translate }}"
                    matTooltipPosition="after"
                    *ngIf="match.participants[1].red_cards > 0"
                >
                    {{ match.participants[1].red_cards }}
                </span>
                <span
                    matTooltip="{{ 'web.scratch_tooltip' | translate }}"
                    matTooltipPosition="after"
                    *ngIf="match.participants[1].scratch"
                >
                    <span class="fa-stack">
                        <i class="far fa-circle fa-stack-2x"></i>
                        <i class="fas fa-plus fa-circle fa-stack-1x liv-scratch"></i>
                    </span>
                </span>
                <span *ngIf="isAwayBatting">
                    <svg class="icon">
                        <use href="#icon-cricket-bat" />
                    </svg>
                </span>
            </div>
        </div>

        <app-match-action-icons
            [matchActions]="matchActions"
            [sportName]="sportName"
            [playerStatistics]="playerStatistics"
            [substitutions]="substitutions"
            class="align-self-center pr-3 app-match-action-icons-{{ sportName }}"
        ></app-match-action-icons>

        <div
            (click)="active()"
            class="table-sport__cell table-sport__cell--time table-sport__cell--time-mob app-cursor d-sm-none app-word-nowrap"
            *ngIf="isMobile && match.match_state == 'after' && page !== 'match-list'"
        >
            <ng-container>{{ match.start_date | livDate : 'localDateShort' }}</ng-container>
        </div>

        <div
            (click)="active()"
            class="table-sport__cell table-sport__cell--time app-cursor"
            *ngIf="isMobile && match.match_state != 'after'"
        >
            <div
                [@state]="match.code_state"
                [@.disabled]="animationsDisabled"
                [class.app-state-color-basic]="
                    match.match_state != 'inplay-live' && match.match_state != 'inplay-pause'
                "
                class="d-none d-sm-block"
            >
                {{ 'status.' + sportName + '.' + match.code_state | translate }}
                <div>
                    <app-match-time
                        *ngIf="match.match_state == 'inplay-live' && match.display_time"
                        [time]="match.minute"
                        [addTime]="match.added_time"
                        [code_state]="match.code_state"
                        [lastTimeUpdate]="match?.last_time_update"
                        [sportName]="sportName"
                        [periodLength]="match.period_length"
                        [stoppageTimeAnnounced]="match!.stoppage_time_announced"
                    ></app-match-time>
                </div>
            </div>
            <div
                [@state]="match.code_state"
                [@.disabled]="animationsDisabled"
                [class.app-state-color-basic]="
                    match.match_state != 'inplay-live' && match.match_state != 'inplay-pause'
                "
                class="d-sm-none app-mob-status"
            >
                <ng-container *ngIf="match.match_state !== 'before'"
                    >{{ 'status.' + sportName + '.' + match.code_state + '_short' | translate }} <br
                /></ng-container>

                <ng-container *ngIf="match.match_state === 'before'">
                    <span class="app-mob-status--small"> {{ match.start_date | livDate : dateFormat }}</span>
                </ng-container>
                <app-match-time
                    *ngIf="match.match_state == 'inplay-live' && match.display_time"
                    [time]="match.minute"
                    [addTime]="match.added_time"
                    [code_state]="match.code_state"
                    [lastTimeUpdate]="match?.last_time_update"
                    [sportName]="sportName"
                    [periodLength]="match.period_length"
                    [stoppageTimeAnnounced]="match!.stoppage_time_announced"
                ></app-match-time>
            </div>
        </div>

        <ng-container *ngIf="match.match_state !== 'before' || !isMobile">
            <app-score
                *ngIf="scoreActive"
                (click)="active()"
                class="app-cursor match-{{ match.match_state }}-{{ sportName }}"
                [sport]="sportName"
                [data]="match.score"
                [oneline]="oneline"
                [match_state]="match.match_state"
                [codeState]="match.code_state"
            ></app-score>
        </ng-container>
        <ng-container *ngIf="isOddsView && match.odds">
            <app-bets-detail
                class="app-bets-detail {{ match.match_state }}"
                [odds]="[match.odds]"
                [isBeforeGame]="match.match_state === 'before'"
                viewType="mobil-match-list"
            ></app-bets-detail>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="!widgetView">
        <ng-container *ngIf="client.use('liveIcon')">
            <div
                *ngIf="isBefore && (hashead2Head || match.isLive) && isTrackerSet; else notLive"
                class="table-sport__cell d-flex align-center --w-30"
            >
                <div *ngIf="match.isLive" class="table-sport__cell--live app-word-nowrap ml-sm-2">
                    {{ 'web.live' | translate }}
                </div>
                <a
                    *ngIf="isMobile && hashead2Head"
                    [routerLink]="[
                        '/compare',
                        sportName + '-' + sportId,
                        match.participants[0]?.id,
                        match.participants[1]?.id
                    ]"
                    class="ml-2 compare-arrows"
                    (click)="onIconEvent('H2H')"
                    data-toggle="tooltip"
                    data-placement="top"
                    data-cy="match-h2h"
                    title="{{ 'web.match_h2h' | translate }}"
                >
                    <mat-icon class="v-mid" fontIcon="compare_arrows"></mat-icon>
                </a>
            </div>
            <ng-template #notLive>
                <div class="table-sport__cell align-center d-none d-sm-flex invisible --w-30">
                    <div class="table-sport__cell--live app-word-nowrap">{{ 'web.live' | translate }}</div>
                </div>

                <div class="d-flex align-items-center" *ngIf="isMobile && hashead2Head">
                    <a
                        [routerLink]="[
                            '/compare',
                            sportName + '-' + sportId,
                            match.participants[0]?.id,
                            match.participants[1]?.id
                        ]"
                        class="compare-arrows d-flex align-center"
                        data-toggle="tooltip"
                        data-placement="top"
                        data-cy="match-h2h"
                        (click)="onIconEvent('H2H')"
                        title="{{ 'web.match_h2h' | translate }}"
                    >
                        <mat-icon class="v-mid" fontIcon="compare_arrows"></mat-icon>
                    </a>
                </div>
            </ng-template>
        </ng-container>
        <div
            *ngIf="match?.comments?.length < 1 || !(hasStream && client.use('video'))"
            class="table-sport__cell table-sport__cell--icon d-none d-md-flex d-xxl-flex"
        >
            &nbsp;
        </div>
        <div
            *ngIf="pageService.debug"
            class="table-sport__cell table-sport__cell--icon d-none d-md-flex d-lg-none d-xxl-flex"
        >
            <a [href]="pageService.backofficeHost + '/matches/edit/' + match.id" target="blank" title="Administration">
                <i class="fas fa-user-shield"></i
            ></a>
        </div>
        <div
            *ngIf="match?.comments?.length > 0 || scoreSeries"
            class="table-sport__cell table-sport__cell--icon d-none d-md-flex d-xxl-flex"
        >
            <ng-container *ngIf="match?.comments?.length > 0; else seriesScore">
                <a
                    [routerLink]="[
                        '/page',
                        'sport',
                        'match',
                        sportName + '-' + sportId,
                        match.id,
                        { match_state: match?.match_state }
                    ]"
                    data-toggle="tooltip"
                    data-placement="top"
                    data-cy="match-info"
                    (click)="onIconEvent('info')"
                    [title]="match.comments?.join(', ')"
                >
                    <svg class="icon icon--12">
                        <use xlink:href="#icon-info" />
                    </svg>
                </a>
            </ng-container>
            <ng-template #seriesScore>
                <!-- Hidden will be fixed in 3.1.0 -->
                <!-- <a
                    [routerLink]="['/page', 'sport', 'match', sportName + '-' + sportId, match.id]"
                    data-toggle="tooltip"
                    data-placement="top"
                    data-cy="match-detail"
                    [title]="scoreSeriesText"
                >
                    <svg class="icon icon--12"><use xlink:href="#icon-info" /></svg
                ></a>-->
            </ng-template>
        </div>

        <div
            *ngIf="
                (match?.comments)!.length < 1 &&
                !scoreSeries &&
                (!hasStream || (hasStream && !client.use('video'))) &&
                hashead2Head
            "
            class="table-sport__cell table-sport__cell--icon d-none d-md-flex d-xxl-flex"
        >
            <a
                [routerLink]="[
                    '/compare',
                    sportName + '-' + sportId,
                    match.participants[0]?.id,
                    match.participants[1]?.id
                ]"
                data-toggle="tooltip"
                data-placement="top"
                data-cy="match-h2h"
                class="compare-arrows"
                (click)="onIconEvent('H2H')"
                title="{{ 'web.match_h2h' | translate }}"
            >
                <mat-icon class="v-mid" fontIcon="compare_arrows"></mat-icon>
            </a>
        </div>

        <div
            *ngIf="
                (match?.comments)!.length < 1 &&
                !scoreSeries &&
                !hashead2Head &&
                (!hasStream || (hasStream && !client.use('video')))
            "
            class="table-sport__cell table-sport__cell--icon d-none d-md-flex d-xxl-flex"
        >
            <a
                [routerLink]="[
                    '/page',
                    'sport',
                    'match',
                    sportName + '-' + sportId,
                    match.id,
                    { match_state: this.match?.match_state }
                ]"
                data-toggle="tooltip"
                data-placement="top"
                data-cy="match-detail"
                title="{{ 'web.match_detail' | translate }}"
            >
                <svg class="icon icon--12">
                    <use xlink:href="#icon-player" />
                </svg>
            </a>
        </div>
        <div
            *ngIf="hasStream && client.use('video')"
            class="table-sport__cell table-sport__cell--icon d-sm-none d-md-flex d-xxl-flex app-stream-container"
        >
            <a
                (click)="active(false, true); onIconEvent('stream')"
                data-toggle="tooltip"
                data-placement="top"
                data-cy="match-stream"
                title="{{ 'web.stream_channel' | translate }}"
            >
                <svg class="icon icon--12 icon--stream icon--16" [class.live-stream]="isLiveStrem">
                    <use xlink:href="#icon-stream" />
                </svg>
            </a>
        </div>
        <div class="table-sport__cell table-sport__cell--icon d-none d-md-flex d-lg-none d-xxl-flex">
            <a
                class="app-cursor"
                *ngIf="sportConfig.hasTracker(sportName, match.tournament_id) && isTrackerSet"
                (click)="active(true); onIconEvent('tracker')"
                data-toggle="tooltip"
                data-placement="top"
                title="{{ 'web.tracker' | translate }}"
                ><svg class="icon icon--12">
                    <use xlink:href="#icon-tracker" />
                </svg>
            </a>
            <a
                class="app-cursor"
                *ngIf="!sportConfig.hasTracker(sportName, match.tournament_id) || !isTrackerSet"
                data-toggle="tooltip"
                class="icon-wrapper disabled"
                data-placement="top"
                title="{{ 'web.tracker' | translate }}"
            >
                <svg class="icon icon--12">
                    <use xlink:href="#icon-tracker" />
                </svg>
            </a>
        </div>
        <div class="table-sport__cell table-sport__cell--icon app-match-favorite-wrapper">
            <app-favorite
                *ngIf="isFavoriteEnabled"
                data-cy="match-list-favorite"
                [attr.data-cyId]="match.id"
                [matchId]="match.id"
                [type]="'match'"
                class="app-match-favorite-wrapper__favorite"
            >
                <span notactive>{{ 'web.add_match_to_favorites' | translate }}</span>
                <span active>{{ 'web.remove_match_from_favorites' | translate }}</span>
            </app-favorite>
            <ng-container *ngIf="participantId">
                <span
                    *ngIf="
                        (participantId === match.participants[0].id && match.winner === 1) ||
                        (participantId === match.participants[1].id && match.winner === 2)
                    "
                    class="badge app-last-matches-badge badge-success mr-1"
                    [class.badge--split]="sportName === 'ice-hockey' && ['aet', 'ap'].includes(match.code_state)"
                    >{{ 'web.last_matches_win_short' | translate }}</span
                >
                <span
                    *ngIf="
                        (participantId === match.participants[0].id && match.winner === 2) ||
                        (participantId === match.participants[1].id && match.winner === 1)
                    "
                    class="badge app-last-matches-badge badge-danger mr-1"
                    [class.badge--split]="sportName === 'ice-hockey' && ['aet', 'ap'].includes(match.code_state)"
                    >{{ 'web.last_matches_lose_short' | translate }}</span
                >
                <span *ngIf="match.winner === 3" class="badge app-last-matches-badge badge-draw mr-1">{{
                    'web.last_matches_draw_short' | translate
                }}</span>
            </ng-container>
        </div>
    </ng-container>
</div>
