

/**
 *  Participant stats match result
 *  @author Livescore <@score-stats.com>
 *  @copyright 2022 livescore
 */

import { SportConfigToken } from '@config/sport.config';
import { Component, Inject, OnInit } from '@angular/core';
import { ParticipantStats } from '@components/participant/statistics/participant-stats.abstract';
import { delay, tap } from 'rxjs/operators';

import { LastForm } from '@widgets/interfaces/widget.interface';

import { sortBy, sumBy } from 'lodash-es';


type Score = {name: string, num: number, prct: number};
type Exact<T> = {[Property in keyof T as Exclude<Property, 'name'>]: T[Property]; } & {name: number};

@Component({
    selector: 'app-participant-stats-match-result',
    templateUrl: './participant-match-result.component.html',
})
export class ParticipantStatsMatchResultComponent extends ParticipantStats implements OnInit {
    [prop: string]: any;

    public scores: Score[] = [];
    public exact: Exact<Score>[] = [];


    public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>) {
        super();
    }

    public reset(): void {
        this.scores = [];
        this.exact = [];
    }

    public ngOnInit(): void {
        this.dataLoaded.pipe(tap(() => {
            if (this.sportConfig.isHockey(this.sportName)) {
                this.periods = ['score_period_1', 'score_period_2', 'score_period_3', 'score_normal_time'];
            }
        }), delay(100)).subscribe(() => {
            const scores: Score[] = [];
            let exact: Exact<Score>[] = [];
            this.form.form.forEach(((f: LastForm) => {
                // const isHome = this.isHome(f.home_team);
                const [home, away] = f.score_normal_time.split(':');

                const key = `${home}:${away}`;
                const sumGoals = +home + +away;

                let fobj = scores?.find(v => v.name === key);
                let fobj2 = exact?.find(v => v.name === sumGoals);

                if (!fobj) {
                    fobj = { name: key, num: 0, prct: 0 };
                    scores.push(fobj);
                }

                if (!fobj2) {
                    fobj2 = { name: sumGoals, num: 0, prct: 0 };
                    exact.push(fobj2);
                }

                fobj.num += 1;
                fobj2.num += 1;
            }));

            this.scores = sortBy(scores, ['num']);
            this.scores = this.scores.reverse();
            const sum = sumBy(this.scores, 'num');

            this.scores = this.scores.map((val): Score => ({ ...val, prct: Math.floor(val.num / sum * 100) }));

            exact = sortBy(exact, ['num']);
            this.exact = exact.reverse();
            const sum2 = sumBy(this.exact, 'num');
            this.exact = this.exact.map((val): Exact<Score> => ({ ...val, prct: Math.floor(val.num / sum2 * 100) }));
        });
    }

    public max(prct: number, max: number = 15): number {
        return Math.max(prct, max);
    }
}
