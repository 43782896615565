<section class="participant-stats mt-2">
    <header class="participant-stats__header">{{ 'web.participant_stats_total_normal_time' | translate }}</header>

    <div class="participant-stats__box">
        <ng-container *ngIf="normalTime">
            <div class="d-flex">
                <div class="--bar-text --bar-text--win text-left flex-grow-1">
                    {{ 'web.wins' | translate }}
                    <span class="--small">({{ normalTime.win }} {{ 'web.matches' | translate }})</span>
                </div>
                <div class="--bar-text --bar-text--draw text-center">
                    {{ 'web.draw_match' | translate }}
                    <span class="--small">({{ normalTime.draw }} {{ 'web.matches' | translate }})</span>
                </div>
                <div class="--bar-text --bar-text--lose text-right flex-grow-1">
                    <span class="--small">({{ normalTime.lose }} {{ 'web.matches' | translate }})</span>
                    {{ 'web.loses' | translate }}
                </div>
            </div>
            <div class="d-flex mb-3">
                <div class="--bar --bar--win --bar--medium text-center" [style.width.%]="normalTime.winPrct">
                    {{ normalTime.winPrct }}%
                </div>
                <div class="--bar --bar--draw --bar--medium text-center" [style.width.%]="normalTime.drawPrct">
                    {{ normalTime.drawPrct }}%
                </div>
                <div class="--bar --bar--lose --bar--medium text-center" [style.width.%]="normalTime.losePrct">
                    {{ normalTime.losePrct }}%
                </div>
            </div>
        </ng-container>
        <!-- Sport with half stats-->
        <div class="participant-stats__total-half" *ngIf="!sportConfig.isHockey(sportName) && period1 && period2">
            <div class="d-flex">
                <div class="flex-shrink-1" [innerHtml]="svg1 | htmlSafe"></div>
                <div class="flex-grow-1 participant-stats__total-half-lh">{{ 'web.period_1half' | translate }}</div>
                <div class="flex-grow-1 participant-stats__total-half-lh text-right">
                    {{ 'web.period_2half' | translate }}
                </div>
                <div class="flex-shrink-1" [innerHtml]="svg2 | htmlSafe"></div>
            </div>
            <div class="d-flex justify-content-between bg-white px-2 py-1">
                <div>
                    <span class="participant-stats__total-half--win">{{ period1.winPrct }}%</span>
                </div>
                <div>{{ 'web.win' | translate }}</div>
                <div>
                    <span class="participant-stats__total-half--win">{{ period2.winPrct }}%</span>
                </div>
            </div>
            <div class="d-flex justify-content-between bg-white px-2 py-1">
                <div>
                    <span class="participant-stats__total-half--draw">{{ period1.drawPrct }}%</span>
                </div>
                <div>{{ 'web.draw_match' | translate }}</div>
                <div>
                    <span class="participant-stats__total-half--draw">{{ period2.drawPrct }}%</span>
                </div>
            </div>
            <div class="d-flex justify-content-between bg-white px-2 py-1">
                <div>
                    <span class="participant-stats__total-half--lose">{{ period1.losePrct }}%</span>
                </div>
                <div>{{ 'web.lose' | translate }}</div>
                <div>
                    <span class="participant-stats__total-half--lose">{{ period2.losePrct }}%</span>
                </div>
            </div>
        </div>
        <!-- Ice Hockey stats-->
        <div
            class="participant-stats__total-half"
            *ngIf="sportConfig.isHockey(sportName) && period1 && period2 && period3"
        >
            <div class="d-flex">
                <div class="participant-stats__total-half-lh" style="width: 150px">&nbsp;</div>
                <div class="flex-grow-1 participant-stats__total-half-lh">
                    <div class="d-inline-block" [innerHtml]="svg1 | htmlSafe"></div>
                    {{ 'web.period_1period' | translate }}
                </div>

                <div class="flex-grow-1 participant-stats__total-half-lh">
                    <div class="d-inline-block" [innerHtml]="svg2 | htmlSafe"></div>
                    {{ 'web.period_2period' | translate }}
                </div>

                <div class="flex-grow-1 participant-stats__total-half-lh">
                    <div class="d-inline-block" [innerHtml]="svg3 | htmlSafe"></div>
                    {{ 'web.period_3period' | translate }}
                </div>
            </div>
            <div class="d-flex bg-white px-2 py-1">
                <div class="" style="width: 150px">{{ 'web.win' | translate }}</div>
                <div class="flex-grow-1">
                    <span class="participant-stats__total-half--win">{{ period1.winPrct }}%</span>
                </div>
                <div class="flex-grow-1">
                    <span class="participant-stats__total-half--win">{{ period2.winPrct }}%</span>
                </div>
                <div class="flex-grow-1">
                    <span class="participant-stats__total-half--win">{{ period3.winPrct }}%</span>
                </div>
            </div>
            <div class="d-flex n bg-white px-2 py-1">
                <div class="" style="width: 150px">{{ 'web.draw_match' | translate }}</div>
                <div class="flex-grow-1">
                    <span class="participant-stats__total-half--draw">{{ period1.drawPrct }}%</span>
                </div>
                <div class="flex-grow-1">
                    <span class="participant-stats__total-half--draw">{{ period2.drawPrct }}%</span>
                </div>
                <div class="flex-grow-1">
                    <span class="participant-stats__total-half--draw">{{ period3.drawPrct }}%</span>
                </div>
            </div>
            <div class="d-flex bg-white px-2 py-1">
                <div class="" style="width: 150px">{{ 'web.lose' | translate }}</div>
                <div class="flex-grow-1">
                    <span class="participant-stats__total-half--lose">{{ period1.losePrct }}%</span>
                </div>
                <div class="flex-grow-1">
                    <span class="participant-stats__total-half--lose">{{ period2.losePrct }}%</span>
                </div>
                <div class="flex-grow-1">
                    <span class="participant-stats__total-half--lose">{{ period3.losePrct }}%</span>
                </div>
            </div>
        </div>
    </div>
</section>
