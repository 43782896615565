<section class="participant-stats mt-2">
    <header class="participant-stats__header">{{ 'web.participant_stats_match_result' | translate }}</header>

    <ng-container *ngIf="normalTime">
        <div class="participant-stats__box">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="participant-stats__item-parent">
                        <div class="d-flex justify-content-between p-2 participant-stats__item">
                            <div class="font-weight-bold">{{ 'web.accurate_score' | translate }}</div>
                        </div>
                        <div class="participant-stats__item p-2" *ngFor="let score of scores; trackBy: trackByIndex">
                            <div class="d-flex">
                                <div class="no-square text-left flex-grow-1">
                                    <span class="participant-stats__total-half--neutral">{{ score.name }}</span>
                                </div>
                                <div class="no-square text-center">{{ score.num }}x</div>

                                <div
                                    class="--bar --bar--win --bar--filled text-center align-self-stretch ml-2"
                                    [style.width.%]="40"
                                >
                                    <div class="--bar--filled --bar--filled-active" [style.width.%]="max(score.prct)">
                                        {{ score.prct }}%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="participant-stats__item-parent">
                        <div class="d-flex justify-content-between p-2 participant-stats__item">
                            <div class="font-weight-bold">{{ 'web.exact_num_goals' | translate }}</div>
                        </div>
                        <div class="participant-stats__item p-2" *ngFor="let exact of exact; trackBy: trackByIndex">
                            <div class="d-flex">
                                <div class="no-square text-left flex-grow-1">
                                    {{ exact.name }} {{ 'web.goals_num' | translate }}
                                </div>
                                <div class="no-square text-center --small">{{ exact.num }}x</div>

                                <div
                                    class="--bar --bar--win --bar--filled text-center align-self-stretch ml-2"
                                    [style.width.%]="40"
                                >
                                    <div class="--bar--filled --bar--filled-active" [style.width.%]="max(exact.prct)">
                                        {{ exact.prct }}%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</section>
