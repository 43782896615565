

/**
 *  Participant stats Under/Over
 *  @author Livescore <@score-stats.com>
 *  @copyright 2022 livescore
 */

import { SportConfigToken } from '@config/sport.config';
import { Component, Inject, OnInit } from '@angular/core';
import { ParticipantStats } from '@components/participant/statistics/participant-stats.abstract';
import { delay, tap } from 'rxjs/operators';

@Component({
    selector: 'app-participant-stats-uo',
    templateUrl: './participant-stats-uo.component.html',
})
export class ParticipantStatsUOComponent extends ParticipantStats implements OnInit {
    [prop: string]: any;

    private total: number = 0;
    private totalHome: number = 0;
    private totalAway: number = 0;


    public constructor(@Inject(SportConfigToken) public sportConfig: Record<string, any>) {
        super();
    }

    public reset(): void {
        this.total = 0;
        this.totalHome = 0;
        this.totalAway = 0;
    }

    public ngOnInit(): void {
        this.dataLoaded.pipe(tap(() => {
            if (this.sportConfig.isHockey(this.sportName)) {
                this.periods = ['score_period_1', 'score_period_2', 'score_period_3', 'score_normal_time'];
                this.uo = this.sportConfig.statistics.underOverSport['ice-hockey'];
            }
            if (this.sportConfig.isFutsal(this.sportName)) {
                this.uo = this.sportConfig.statistics.underOverSport.futsal;
            }
            if (this.sportConfig.isHandball(this.sportName)) {
                this.uo = this.sportConfig.statistics.underOverSport.handball;
            }
            if (this.sportConfig.isSoccer(this.sportName)) {
                this.uo = this.sportConfig.statistics.underOverSport.soccer;
            }
        }), delay(100)).subscribe(() => {

        });
    }
}
