/**
 *  Animation score change
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import {
    style,
    animate,
    transition,
    keyframes,
    group,
} from '@angular/animations';


export const STATE_ANIMATION = [
    transition('*=>*', [
        style({

            padding: '1px',
        }),
        group([
            animate(
                '20s 0.3s',
                keyframes([
                    style({ color: 'white', backgroundColor: '#df0303' }),
                    style({ color: 'white', backgroundColor: '#df0303' }),
                    style({ color: 'white', backgroundColor: '#df0303' }),
                    style({ color: 'white', backgroundColor: '#df0303' }),
                    style({ color: 'white', backgroundColor: '#df0303' }),
                    style({ color: 'white', backgroundColor: '#df0303' }),
                    style({ color: 'white', backgroundColor: '#df0303' }),
                    style({ color: 'white', backgroundColor: '#df0303' }),
                    style({ color: 'white', backgroundColor: '#df0303' }),
                    style({ color: 'white', backgroundColor: '#df0303' }),
                ]),
            ),
            animate(
                '2s 0s cubic-bezier(0.455, 0.030, 0.515, 0.955)',
                style({
                    transform: 'rotate3d(0,1, 0, 360deg)',
                }),
            ),
        ]),
    ]),
];
