<div class="d-flex flex-row align-items-center">
    <div class="w-100 ml-xl-2" *ngIf="$call | async as form">
        <select
            class="custom-select border-light2 mb-3 w-50"
            [ngModel]="lastMatches"
            (ngModelChange)="onLastMatchesChange($event)"
        >
            <option value="10">{{ 'web.last_matches_num' | translate: { num: 10 } }}</option>
            <option value="20">{{ 'web.last_matches_num' | translate: { num: 20 } }}</option>
            <option value="30">{{ 'web.last_matches_num' | translate: { num: 30 } }}</option>
        </select>
        <ng-content></ng-content>
    </div>
    <div *ngIf="isLoading" class="w-100 text-center">
        <ng-container *ngIf="isLoading">
            <app-page-loader class="app-tracker-loader"></app-page-loader>
        </ng-container>
        {{ 'web.loading' | translate }} ...
    </div>
</div>
