<div>
    <h5 class="font-weight-bold text-uppercase mb-2 app-fomr-h1">
        {{ 'web.participant_form_head' | translate }} {{ totalMatches }}
        <i
            class="fas fa-info-circle"
            data-toggle="tooltip"
            data-placement="right"
            title="{{ 'web.participant_form_info' | translate }}"
        ></i>
    </h5>

    <div id="doughnut">
        <div class="doughnut-text">{{ prct }}%</div>
    </div>

    <div class="d-flex justify-content-center">
        <div class="doughnut-color doughnut-color-1">{{ trans[0] }}</div>
        <div class="doughnut-color doughnut-color-2">{{ trans[1] }}</div>
        <div class="doughnut-color doughnut-color-3">{{ trans[2] }}</div>
    </div>
</div>
